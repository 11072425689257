import axios from "axios";
var url_atual = window.location.href;

const api = axios.create({
 // baseURL: "http://127.0.0.1:3333",
   baseURL:
    url_atual.substr(7, 9) === "localhost"
      ? "https://iotmonitor-api-hml.uberlandia.mg.gov.br/"
      : "iotmonitor-api",
});

export default api;
