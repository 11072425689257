import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Card, CardBody, Container, Row, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";

const cadastroPayload = () => {
  const payloadsArray = [
    { id: "UPM", name: "UPM - UpLink Manual" },
    { id: "UPA", name: "UPA - UpLink automático" },
    { id: "DWN", name: "DWN - DownLink" },
  ];

  const [makers, setMakers] = useState([]);

  const [selectedMakers, setSelectedMakers] = useState("");
  const [selectedPayload, setSelectedPayload] = useState(payloadsArray[0]);
  const [protocol, setProtocol] = useState("");
  const [protocolVersion, setProtocolVersion] = useState("");
  const [base, setBase] = useState("64");

  useEffect(() => {
    api.get("/makerslora").then((response) => {
      let array = [];
      response.data.map((elem) => {
        array.push({
          id: elem.OID_FABRICANTE_LORA,
          name: elem.DS_FABRICANTE_LORA,
        });
      });
      setSelectedMakers(array[0].id);
      setMakers(array);
    });
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    let body = {
      OID_FABRICANTE_LORA: selectedMakers,
      DS_PROTOCOLO: protocol,
      NR_PROTOCOLO_VERSAO: protocolVersion,
      TP_PAYLOAD: selectedPayload.id,
      NR_BASE_DECRIPTACAO: base,
    };

    api
      .post("/makerspayloads", body)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Payload cadastrado! Para a ativação do payload aguarde o desenvolvimento do algoritmo!",
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Confira todos os dados e faça o cadastro completo!",
        });
      });
  };

  const selectHandler = (prop) => (event) => {
    if (prop === "makers") {
      setSelectedMakers(event.target.value);
    } else if (prop === "payload") {
      let strg = event.target.value.slice(0, 3);
      setSelectedPayload({ id: strg, name: event.target.value });
    }
  };

  const inputHandler = (prop) => (event) => {
    if (prop === "protocol") {
      setProtocol(event.target.value);
    } else if (prop === "protocolVersion") {
      setProtocolVersion(event.target.value);
    } else if (prop === "base") {
      setBase(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Payload</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to={"/cadastro/Payload"}>
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
 
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Fabricante Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        className="form-control form-control-lg"
                        onChange={selectHandler("makers")}
                      >
                        {makers?.map((fabricante, key) => (
                          <option key={key} value={fabricante.id}>
                            {fabricante.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Protocolo
                    </label>
                    <div className="col-md-6">
                      <input
                        name="DS_PROTOCOLO"
                        required
                        className="form-control form-control-lg"
                        type="text"
                        onChange={inputHandler("protocol")}
                        value={protocol}
                        maxLength={50}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Versão do Protocolo
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_PROTOCOLO_VERSAO"
                        onChange={inputHandler("protocolVersion")}
                        value={protocolVersion}
                        maxLength={10}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Payload
                    </label>
                    <div className="col-md-6">
                      <select
                        name="TP_PAYLOAD"
                        className="form-control form-control-lg"
                        onChange={selectHandler("payload")}
                      >
                        {payloadsArray.map((elem, key) => (
                          <option key={key} value={elem.id}>
                            {elem.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Base Criptografia
                    </label>
                    <div className="col-md-6">
                      <select
                        name="NR_BASE_DECRIPTACAO"
                        className="form-control form-control-lg"
                        onChange={inputHandler("base")}
                      >
                        <option value="64">64</option>
                        <option value="32">32</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Cadastrar <i className="dripicons-document-new" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default cadastroPayload;
