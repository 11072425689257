import React, { useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Row } from "reactstrap";
import L from "leaflet";
import api from "../../services/api";
import redPoint from "../../assets/images/maps/marker-icon-red.png";
import greenPoint from "../../assets/images/maps/marker-icon-green.png";
import bluePoint from "../../assets/images/maps/marker-icon-blue.png";
import blackPoint from "../../assets/images/maps/marker-icon-black.png";
import yellowPoint from "../../assets/images/maps/marker-icon-yellow.png";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png").default,
  iconUrl: require("leaflet/dist/images/marker-icon.png").default,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
});

const Maps = () => {
  const [location, setLocation] = useState([]);
  const position = [-18.918, -48.2766];

  const iconRed = new L.Icon({
    iconUrl: redPoint,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const iconGreen = new L.Icon({
    iconUrl: greenPoint,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const iconBlue = new L.Icon({
    iconUrl: bluePoint,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const iconBlack = new L.Icon({
    iconUrl: blackPoint,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const iconYellow = new L.Icon({
    iconUrl: yellowPoint,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 90000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    getLocation();
  }, []);
  const getLocation = () => {
    api.get("/maps/location").then((response) => {
      let arr = formatArray(response.data);
      setLocation(arr);
    });
  };

  useEffect(() => {
    const svgElements = document.querySelectorAll("svg");

    svgElements.forEach((svg) => {
      svg.style.setProperty("display", "none", "important");
    });
  }, []);

  const alarmText = (item) => {
    let txt = "";
    if (item.TOT90 == 0) {
      txt = "Sem pulso a mais de 90 dias";
    } else {
      if (
        (item.LORAS24 == 1 && item.LORAS12 == 1) ||
        (item.MEDIDORES_24 == 0 && item.MEDIDORES_12 == 0)
      ) {
        txt += " Sem consumo a mais de 24 h";
      } else if (item.LORAS12 == 1 || item.MEDIDORES_12 == 0) {
        txt += " Sem consumo a mais de 12 h";
      }

      if (item.ALARME != null) {
        if (item.ALARME.indexOf("Corte") !== -1) {
          if (txt.length > 1) {
            txt += ", ";
          }
          txt += " Corte de Cabo do Pulso";
        }
        if (item.ALARME.indexOf("Vazamento") !== -1) {
          if (txt.length > 1) {
            txt += ", ";
          }
          txt += " Possível Vazamento";
        }
        if (item.ALARME.indexOf("Ataque") !== -1) {
          if (txt.length > 1) {
            txt += ", ";
          }
          txt += " Ataque Magnético";
        }
      }

      if (item.PENULTIMO != null) {
        if (parseInt(item.PENULTIMO) > parseInt(item.ULTIMO)) {
          if (txt.length > 1) {
            txt += ", ";
          }
          txt += " Consumo Reverso";
        }
      }
    }

    return txt;
  };

  const icon = (item) => {
    let txt = "";
    if (item.TOT90 == 0) {
      return iconBlack;
    } else {
      if (
        (item.LORAS24 == 1 && item.LORAS12 == 1) ||
        (item.MEDIDORES_24 == 0 && item.MEDIDORES_12 == 0)
      ) {
        return iconRed;
      } else if (item.LORAS12 == 1 || item.MEDIDORES_12 == 0) {
        return iconYellow;
      }

      if (item.ALARME != null) {
        if (
          item.ALARME.indexOf("Corte") !== -1 ||
          item.ALARME.indexOf("Vazamento") !== -1 ||
          item.ALARME.indexOf("Ataque") !== -1
        ) {
          return iconBlue;
        }
      }
    }

    return iconGreen;
  };
  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        longitude: elem.DS_LOCALIZACAO_LONGITUDE,
        latitude: elem.DS_LOCALIZACAO_LATITUDE,
        equip: elem.NR_HIDROMETRO,
        device: elem.NR_EQUIPAMENTO,
        location: elem.DS_LOCALIDADE,
        alarm: alarmText(elem),
        icone: icon(elem),
      });
    });
    return arr;
  };
  return (
    <React.Fragment>
      <Row>
        <div style={{ marginTop: "70px" }}>
          <Map
            center={position}
            zoom={12}
            style={{ height: "80vh", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {location?.map((data, key) => (
              <Marker
                key={key}
                position={[data.longitude, data.latitude]}
                icon={data.icone}
              >
                <Popup>
                  <strong>Device: {data.device}</strong>
                  <br />
                  <strong>Hidrômetro: {data.equip}</strong>
                  <br />
                  <strong>Local de instalação: {data.location}</strong>
                  <br />
                  <strong>--</strong>
                  <br />
                  <strong>Alarmes: {data.alarm}</strong>
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <strong> </strong>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <strong> </strong>
                      <a
                        href={`/dashboard?${data.equip}`}
                        title="Dashboard"
                        style={{
                          backgroundColor: "#c4c4c4",
                          borderRadius: 50,
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="dripicons-arrow-right"
                          style={{ fontSize: 20, paddingTop: 5 }}
                        />
                      </a>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
          </Map>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Maps;
