import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Card, CardBody, Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";
import validator from "validator";

const cadastroFabricanteLora = () => {
  const initialState = {
    maker: "",
    contact: "",
    email: "",
    tel: "",
  };

  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  const contactRegex = /[^a-z A-Z]/gm;

  const [addMaker, setAddMaker] = useState(initialState);

  const onChangeHandler = (prop) => (event) => {
    setAddMaker({ ...addMaker, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const errorStyle = "margin-bottom: 0; font-size: 80%;";
    if (addMaker.contact.match(contactRegex) || addMaker.contact.length < 3) {
      Swal.fire({
        icon: "warning",
        html:
          '<p style="margin-bottom: 0;">Digite Um <b>CONTATO</b> Válido</p>' +
          `<p style="${errorStyle}">Apenas Letras e Espaços</p>`,
      });
      return;
    }

    if (!addMaker.email.match(emailRegex)) {
      Swal.fire({
        icon: "warning",
        text: "Digite um EMAIL Válido",
      });
      return;
    }

    if (addMaker.tel.match(/\D/g)) {
      Swal.fire({
        icon: "warning",
        html:
          '<p style="margin-bottom: 0;">Digite Um <b>TELEFONE</b> Válido</p>' +
          `<p style="${errorStyle}">Apenas NÚMEROS</p>`,
      });
      return;
    }

    const body = {
      DS_FABRICANTE_LORA: addMaker.maker,
      NM_CONTATO: addMaker.contact,
      DS_EMAIL: addMaker.email,
      NR_TELEFONE: addMaker.tel,
    };
    
    api.post("/makerslora", body).then((response) => {
        Swal.fire({
          icon: "success",
          title: "Fabricante cadastrado!",
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Confira todos os dados e faça o cadastro completo!",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Fabricante Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/FabricanteLora">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Fabricante
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_FABRICANTE_LORA"
                        required
                        value={addMaker.maker}
                        onChange={onChangeHandler("maker")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Contato
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NM_CONTATO"
                        required
                        value={addMaker.contact}
                        onChange={onChangeHandler("contact")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      E-mail
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_EMAIL"
                        required
                        value={addMaker.email}
                        onChange={onChangeHandler("email")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Telefone
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_TELEFONE"
                        required
                        value={addMaker.tel}
                        onChange={onChangeHandler("tel")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Cadastrar <i className="dripicons-document-new" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default cadastroFabricanteLora;
