import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Link,
  useParams
} from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import api from "../../services/api";

const editEquipamentoLora = () => {
  const [fabricantes, setFabricantes] = useState([]);

  const [makers, setMakers] = useState('');
  const [desc, setDesc] = useState('');
  const [model, setModel] = useState(''); 
  const [number, setNumber] = useState('');
  const [ativo, setAtivo] = useState("");

  // get fabricantes
  useEffect(() => {
    api.get("/makerslora").then((response) => {
      setFabricantes(response.data);
    }); 
  }, []);

  const [EquipamentoLora, setEquipamentoLora] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    api.get(`/equipmentslora/${id}`).then((response) => {
      setEquipamentoLora(response.data[0]);
      setMakers(response.data[0].OID_FABRICANTE_LORA);
      setDesc(response.data[0].DS_DESCRICAO_EQUIPAMENTO);
      setModel(response.data[0].DS_MODELO_EQUIPAMENTO);
      setNumber(response.data[0].NR_EQUIPAMENTO);
      setAtivo(response.data[0].TP_ATIVO);
    });
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let body = {
      OID_FABRICANTE_LORA: makers,
      DS_DESCRICAO_EQUIPAMENTO: desc,
      DS_MODELO_EQUIPAMENTO: model,
      NR_EQUIPAMENTO: number,
      TP_ATIVO:ativo
    }
    
    api
      .put(`/equipmentslora/${id}`, body)
      .then((response) => {
    		Swal.fire({
    			icon: 'success',
    			title: 'Equipamento LORA atualizado!',
    		});
    	})
    	.catch(function (error) {
    		Swal.fire({
    			icon: 'warning',
				title: 'Falha na Atualização, verifique os dados e tente novamente',
    		});
    	});
  };

  const onChangeHandler = (prop) => (event) => {
    if (prop === "makers") {
      setMakers(event.target.value);
    } else if (prop === "desc") {
      setDesc(event.target.value);
    } else if (prop === "model") {
      setModel(event.target.value);
    } else if (prop === "number") {
      setNumber(event.target.value);
    } else if (prop === "ativo") {
      setAtivo(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Equipamento Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/EquipamentoLora">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmitHandler}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_FABRICANTE_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Fabricante Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("makers")}
                        value={makers}
                      >
                        {fabricantes?.map((fabricante, key) => (             
                          <option
                            key={key}
                            value={fabricante.OID_FABRICANTE_LORA}
                          >
                            {fabricante.DS_FABRICANTE_LORA}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_DESCRICAO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Descrição Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_EQUIPAMENTO"
                        value={desc}
                        onChange={onChangeHandler("desc")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_MODELO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Modelo Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_EQUIPAMENTO"
                        value={model}
                        onChange={onChangeHandler("model")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <label
                      htmlFor="NR_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Número Equipamento
                      <div></div>
                      (MAC ADDRESS)
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_EQUIPAMENTO"
                        value={number}
                        onChange={onChangeHandler("number")}
                      />
                    </div>
                  </Row>
                   <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Status
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        id="TP_ATIVO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("ativo")}
                        value={ativo}
                      >
                        <option value="S">Ativo</option>
                        <option value="N">Inativo</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Atualizar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default editEquipamentoLora;
