import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Logs from "../pages/Dashboard/logs";


// Cadastros
import Hidrometro from "../pages/Cadastro/Hidrometro";
import cadastroHidrometro from "../pages/Cadastro/Hidrometro-cadastro";
import editHidrometro from "../pages/Cadastro/Hidrometro-edit";

import FabricanteLora from "../pages/Cadastro/FabricanteLora";
import cadastroFabricanteLora from "../pages/Cadastro/FabricanteLora-cadastro";
import editFabricanteLora from "../pages/Cadastro/FabricanteLora-edit";

import EquipamentoLora from "../pages/Cadastro/EquipamentoLora";
import cadastroEquipamentoLora from "../pages/Cadastro/EquipamentoLora-cadastro";
import editEquipamentoLora from "../pages/Cadastro/EquipamentoLora-edit";

import Payload from "../pages/Cadastro/Payload";
import cadastroPayload from "../pages/Cadastro/Payload-cadastro";
import editPayload from "../pages/Cadastro/Payload-edit";

import Consumo from "../pages/Cadastro/Consumo";
import EquipamentoDmae from "../pages/Cadastro/EquipamentoDmae";
import cadastroEquipamentoDmae from "../pages/Cadastro/EquipamentoDmae-cadastro";
import editEquipamentoDmae from "../pages/Cadastro/EquipamentoDmae-edit";
import usuarioEmpresaCadastro from "../pages/UsuarioEmpresa/usuarioEmpresa-cadastro";
import usuarioEmpresaListar from "../pages/UsuarioEmpresa/usuarioEmpresa-listar";

import comsumo2 from "../pages/Cadastro/Consumo2";
import Calibragem from "../pages/Calibragem/Calibragem";

import acoesEquipamento from "../pages/Cadastro/AcoesEquipamento";
import acoesEquipamentoUpdate from "../pages/Cadastro/AcoesEquipamento-update";
import Maps from "pages/Dashboard/maps";
 
const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/logs", component: Logs },
  { path: "/maps", component: Maps },


  // cadastros
  { path: "/cadastro/hidrometro", component: Hidrometro },
  { path: "/cadastro/hidrometro-cadastro", component: cadastroHidrometro },
  { path: "/cadastro/hidrometro-edit/:id/", component: editHidrometro },

  { path: "/cadastro/FabricanteLora", component: FabricanteLora },
  {
    path: "/cadastro/FabricanteLora-cadastro",
    component: cadastroFabricanteLora,
  },
  { path: "/cadastro/FabricanteLora-edit/:id/", component: editFabricanteLora },

  { path: "/cadastro/Payload", component: Payload },
  { path: "/cadastro/Payload-cadastro", component: cadastroPayload },
  { path: "/cadastro/Payload-edit/:id", component: editPayload },


  { path: "/cadastro/EquipamentoLora", component: EquipamentoLora },
  {
    path: "/cadastro/EquipamentoLora-cadastro",
    component: cadastroEquipamentoLora,
  },
  {
    path: "/cadastro/EquipamentoLora-edit/:id/",
    component: editEquipamentoLora,
  },

  { path: "/cadastro/EquipamentoDmae", component: EquipamentoDmae },
  {
    path: "/cadastro/EquipamentoDmae-cadastro",
    component: cadastroEquipamentoDmae,
  },
  {
    path: "/cadastro/EquipamentoDmae-edit/:id/",
    component: editEquipamentoDmae,
  },

  { path: "/consumo", component: Consumo },

  { path: "/usuarioEmpresa/cadastro", component: usuarioEmpresaCadastro },
  { path: "/usuarioEmpresa/listar", component: usuarioEmpresaListar },

  { path: "/consumocondomino", component: comsumo2 },

  { path: "/calibragem/", component: Calibragem },
  { path: "/calibragem/:state/", component: Calibragem },

  { path: "/cadastro/acoesequipamento", component: acoesEquipamento },
  {
    path: "/cadastro/acoesequipamento-update/:id/",
    component: acoesEquipamentoUpdate,
  },
  {
    path: "/cadastro/acoesequipamento-update/:id/:state",
    component: acoesEquipamentoUpdate,
  },

  // // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
