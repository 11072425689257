import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import ReactInputMask from "react-input-mask";

import {
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Input,
  Label,
  Badge,
  Button,
  Modal,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import ExcelFile from "helpers/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-data-export/dist/ExcelPlugin/elements/ExcelColumn";

const cadastroHidrometro = () => {
  const initialState = {
    id: "",
    number: "",
    desc: "",
    model: "",
    status: "",
    tipo: "",
  };
  const [showModal, setShowModal] = useState(false);
  const [hidrometros, setHidrometros] = useState([]);
  const [hydrometers, setHydrometers] = useState([]);
  const [master, setmaster] = useState("");
  const [capacidade, setCapacidade] = useState(-1);
  const [acesso, setAcesso] = useState("");

  const [textCapacity, setTextCapacity] = useState("");

  const [search, setSearch] = useState(initialState);
  const [selecionados, setSelecionados] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [checked, setChecked] = useState(false);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (capacidade == -1) {
      Swal.fire({
        icon: "warning",
        text: "Insira a capacidade do hidrometro!",
      });
      return
    }

    api
      .post("/hydrometers", data)
      .then((response) => {
        Array.from(document.querySelectorAll("input,select")).forEach(
          (input) => (input.value = "")
        );
        Swal.fire({
          icon: "success",
          title: "Hidrômetro cadastrado!",
        });
      })
      .catch(function (error) {
        let msgError = error.response.data.split('*');
        msgError = msgError[1] ? msgError[1] : "Confira todos os dados e faça o cadastro completo!"
        "Confira todos os dados e faça o cadastro completo!";
        Swal.fire({
          icon: "warning",
          text: msgError,
        });
      });
  };
  const getEmpresas = () => {
    api.post("/empresaservice/empresas").then((response) => {
      response.data.map((elem) => {
        empresas.push({
          id: elem.OID_EMPRESA,
          name: elem.NM_RAZAO_SOCIAL,
        });
      });
      setEmpresas(empresas);
    });
  };

  useEffect(() => {
    getEmpresas();
    api.get("/hydrometers").then((response) => {
      let arr = [];
    });
    api.get("/hydrometersmastercdmn").then((response) => {
      setHidrometros(response.data);
    });
  }, []);

  async function saveinliste() {
    for (let i = 0; i < selecionados.length; i++) {
      const body = {
        OID_HIDROMETRO_MASTER: master,
        NR_HIDROMETRO_CDMN: selecionados[i],
      };

      await api.post("/hydrometersmastercdmn", body).then((response) => { });
    }
    setShowModal(false);
  }
  const handleSelectCapacidade = (event) => {
    setCapacidade(event.target.value);
    if (event.target.value == -1) {
      setTextCapacity("")
    } else if (event.target.value == 0) {
      setTextCapacity("4 Digitos: 9999")
    } else if (event.target.value == 1) {
      setTextCapacity("5 Digitos: 99999")
    } else if (event.target.value == 2) {
      setTextCapacity("6 Digitos: 999999")
    } else if (event.target.value == 3) {
      setTextCapacity("7 Digitos: 9999999")
    } else if (event.target.value == 4) {
      setTextCapacity("8 Digitos: 99999999")
    } else if (event.target.value == 5) {
      setTextCapacity("9 Digitos: 999999999")
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Hidrometro</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/hidrometro">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
 
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Número Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_HIDROMETRO", { required: true })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_HIDROMETRO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Descrição
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("DS_DESCRICAO_HIDROMETRO", {
                          required: true,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_HIDROMETRO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Modelo
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("DS_MODELO_HIDROMETRO", {
                          required: true,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_HIDROMETRO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Vazão
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_RANGE_VAZAO", {
                          required: true,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_RANGE_VAZAO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Pulso/litro
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_CONSUMO_LITROS")}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_CONSUMO_LITROS"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Telefone
                    </label>
                    <div className="col-md-6">
                      <ReactInputMask
                        {...register("NR_CONTATO")}
                        mask="(99)99999-9999"
                        className="form-control form-control-lg"
                        type="text"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-2">
                      <label htmlFor="descricao">
                        Capacidade
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        {...register("ID_CAPACIDADE")}
                        id="ID_CAPACIDADE"
                        className="form-control form-control-lg"
                        onChange={handleSelectCapacidade}
                        defaultValue={-1}
                      >
                        <option value="-1">Selecionar</option>
                        <option value="0">0</option>

                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>

                      </select>
                      <span className="text-info">{textCapacity}</span>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao">
                        Acesso
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        {...register("TP_ACESSO")}
                        id="TP_ACESSO"
                        name="TP_ACESSO"
                        className="form-control form-control-lg"
                      >
                        <option value="L">Livre</option>
                        <option value="IT">Interno Trancado</option>
                        <option value="ET">Externo Trancado</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Master
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("TP_HIDROMETRO")}
                        className="form-check-input p-2"
                        type="checkbox"
                        name="TP_HIDROMETRO"
                        value="M"
                        onChange={(e) => {
                          setChecked(e.target.checked);
                        }}
                      />
                    </div>
                  </Row>
                  {checked ? (<>
                    <Row className="mb-3">
                      <label
                        htmlFor="descricao"
                        className="col-md-2 col-form-label"
                      >
                        Empresa Responsável
                      </label>
                      <div className="col-md-6">
                        <select
                          {...register("OID_EMPRESA", { required: true })}
                          className="form-control form-control-lg"
                          name="OID_EMPRESA"
                          defaultValue=""
                        >
                          <option value="">Selecionar</option>
                          {empresas.map((empresa, index) => (
                            <option key={index} value={empresa.id}>
                              {empresa.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                    <label htmlFor="NM_CONDOMINIO" className="col-md-2 col-form-label">
                      Nome do Condominio
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NM_CONDOMINIO", { required: true })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NM_CONDOMINIO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                    </>
                  ) : (
                    <></>
                  )}

                  <Button color="primary" className="btn-lg" type="submit">
                    Cadastrar <i className="dripicons-document-new" />
                  </Button>
                </form>
              </CardBody>

              <Modal isOpen={showModal}>
                <Container fluid>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle"></th>
                          <th className="align-middle">Número Hidrômetro</th>
                          <th className="align-middle">Bloco</th>
                          <th className="align-middle">Complemento</th>
                          <th className="align-middle">IDA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hidrometros?.map((hydrometer, key) => (
                          <tr key={"_tr_" + key}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => {
                                  selecionados.push(
                                    hydrometer.NR_HIDROMETRO_IMOVEL
                                  );
                                }}
                              />
                            </td>
                            <td>{hydrometer.NR_HIDROMETRO_IMOVEL}</td>
                            <td>{hydrometer.DS_BLOCO_IMOVEL}</td>
                            <td>{hydrometer.DS_COMPLEMENTO_IMOVEL}</td>
                            <td>{hydrometer.NR_IDA_IMOVEL}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Container>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      saveinliste();
                    }}
                  >
                    Salvar
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default cadastroHidrometro;
