import { set, sum } from "lodash";
import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import { useForm } from "react-hook-form";
import { Bar } from "react-chartjs-2";

import MetaTags from "react-meta-tags";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const initialData = [
  {
    MES: "",
    MAIORPULSO: "",
    MENORPULSO: "",
    DEVICE: "",
    NR_HIDROMETRO: "",
    IDA: "",
    DS_LOCALIDADE: "",
  },
  {
    MES: "",
    MAIORPULSO: "",
    MENORPULSO: "",
    DEVICE: "",
    NR_HIDROMETRO: "",
    IDA: "",
    DS_LOCALIDADE: "",
  },
];

const initialData2 = [
  {
    MES: "",
    MAIORPULSO: "",
    MENORPULSO: "",
    DEVICE: "",
    NR_HIDROMETRO: "",
    IDA: "",
    DS_LOCALIDADE: "",
  },
];
const Consumo2 = () => {
  const [consumptions, setConsumptions] = useState([]);
  const [pulses, setPulses] = useState([]);
  const [localidade, setLocalidade] = useState([]);
  const [hidrometro, setHidrometro] = useState("");
  const [ida, setIda] = useState("");
  const [values, setValues] = useState([]);
  const [data, setData] = useState(initialData);
  const [data2, setData2] = useState(initialData2);
  const [mes, setMes] = useState([]);
  const [date1, setDate1] = useState("");
  const [currentReading, setCurrentReading] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [qtd, setQtd] = useState("");
  const [qtdAlocada, setQtdAlocada] = useState("");
  const [currentIda, setCurrentIda] = useState("");
  const [meterAuto, setMeterAuto] = useState("");
  const [meterMacro, setMeterMacro] = useState("");
  const [showData, setShowData] = useState(false);
  const [consumptioDifference, setConsumptioDifference] = useState("");
  const [showDownload, setShowDownload] = useState("none");
  const [dataExcel2, setDataExcel] = useState([]);
  const [service, setService] = useState([]);
  const [modal, setModal] = useState(false);
  const [macro, setMacro] = useState([]);
  const [atualiza, setAtualiza] = useState("block");
  const [select, setSelect] = useState(-1);
  const [load, setLoad] = useState(true);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    var date = new Date();

    date.setDate(date.getDate() - 5);
    setDate1(date.toISOString().slice(0, 10));
    const consumptionsMap = new Map();
    consumptions.forEach((consumption) => {
      const oldValues = consumptionsMap.get(consumption.pulse_id) || [];

      consumptionsMap.set(consumption.CD_META_DEVICE_ADDR, [
        consumption.NR_VLR_PULSO,
        ...oldValues,
      ]);
    });

    consumptionsMap.forEach((values, key) => {
      const newValues = values.map((value) => Number(value));

      const minPulse = Math.min(...newValues);
      const maxPulse = Math.max(...newValues);

      const pulseDifference = maxPulse - minPulse;
      consumptionsMap.set(key, pulseDifference);
    });

    consumptionsMap.forEach((value, key) => {
      setPulses((oldValues) => [
        oldValues.map((pulse) => ({
          ...pulse,
          id: key,
          value,
        })),
      ]);
    });

    api.post("/empresaservice/empresas").then((response) => {
      setService(response.data);
    });
    api.get("/hydrometersmastercdmn/macro/0").then((response) => {
      setMacro(response.data);
    });
  }, [consumptions]);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    setSelect(-1);
    if (
      data.NR_IDA === "" &&
      data.CD_META_DEVICE_ADDR === "" &&
      data.NR_HIDROMETRO === "" &&
      data.NR_CONTA_IMOVEL === "" &&
      data.DT_FILTER_INI === "" &&
      data.DT_FILTER_FIM === "" &&
      data.SERVICE === ""
    ) {
      Swal.fire({
        icon: "warning",
        text: "Por Favor Preencha ao Menos um Campo",
      });
      return;
    }

    if (data.DT_FILTER_INI > data.DT_FILTER_FIM) {
      Swal.fire({
        icon: "warning",
        text: "A data Fim não pode menor que a data Inicio",
      });
      return;
    }
    if (!data.DT_FILTER_INI || !data.DT_FILTER_FIM) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, insira uma data inicial e uma data final para realizar a consulta!",
      });
      return;
    }
    if (data.RGI_PRINCIPAL != "") {
      setShowDownload("Block");
    } else {
      setShowDownload("none");
    }
    if (data.NR_IDA != "" || data.NR_HIDROMETRO != "") {
      setConsumptioDifference("Consumo/Diferença");
    } else {
      setConsumptioDifference("");
    }
    let oldValue = {};
    api
      .post("/cdmndata/filter", data)
      .then((response) => {
        if (response.data.error) {
          Swal.fire({
            icon: "war",
            timer: 1000,
          });
        }
        Swal.fire({
          icon: "success",
          timer: 1000,
        });

        const result = [];
        for (var i = 0; i < response.data.length; i++) {
          const consumption = response.data[i];

          const data = {
            ...consumption,
            DS_MODELO_HIDROMETRO: consumption.DS_MODELO_HIDROMETRO,
            DS_LOCALIDADE: consumption.DS_LOCALIDADE,
            NR_DIFERENCA_CONSUMO:
              Math.trunc(consumption.NR_PULSO) -
                Math.trunc(oldValue.NR_PULSO) || 0,
            NR_QUANTIDADE_LITROSMC: consumption.NR_QUANTIDADE_LITROS / 1000,
            DT_RX_TIMED: new Date(consumption.DT_RX_TIME).toLocaleDateString(),
            DT_RX_TIMEH: new Date(consumption.DT_RX_TIME).toLocaleTimeString(),
            DT_LEITURA: new Date(
              new Date(consumption.DT_LEITURA).getTime() - 10800000
            ),
          };
          oldValue = consumption;
          result.push(data);
        }
        setCurrentReading(
          parseInt(response.data[0].VR_LEITURA_LITROS) +
            parseInt(response.data[0].VR_RELOJOARIA_INICIAL)
        );
        setCurrentIda("");
        setMeterMacro("");
        setMeterAuto("");
        setCurrentCompany("");
        setConsumptions(result.reverse());
        let dataExcel = [];
        let objExcel;
        let oldData = result;
        let leitura;
        let time;
        for (i = 0; i < oldData.length; i++) {
          objExcel = {
            MACRO: i == 0 ? oldData[i].CD_LIGACAO_RGI_PRINCIPAL : "",
            IDA: oldData[i].NR_IDA_IMOVEL,
            HIDROMETRO: oldData[i].NR_HIDROMETRO_IMOVEL,
            CONTA_IMOVEL: oldData[i].NR_CONTA_IMOVEL,
            COMPLEMENTO_IMOVEL: oldData[i].DS_COMPLEMENTO_IMOVEL,
            DATA: oldData[i].DT_LEITURA.toLocaleDateString(),
            HORA: oldData[i].DT_LEITURA.toLocaleTimeString(),
            PULSO: oldData[i].NR_PULSO,
            LITROS: oldData[i].VR_LEITURA_LITROS,
            CONSUMO: oldData[i].NR_DIFERENCA_CONSUMO,
            LEITURA_ATUAL:
              parseInt(oldData[i].VR_LEITURA_LITROS) +
              parseInt(oldData[i].VR_RELOJOARIA_INICIAL),
          };
          if (compareIda(dataExcel, objExcel.IDA) === false) {
            dataExcel.push(objExcel);
          }
        }
        setDataExcel(dataExcel);
      })
      .catch(function (error) {
        setConsumptions([]);

        console.log(error);
        Swal.fire({
          icon: "warning",
          text: "Nenhum resultado encontrado, refaça a sua busca!",
        });
      });

    setShowData(false);
  };
  const changeDate = (
    reading,
    ida,
    principal,
    automaton,
    company,
    qtd,
    qtdAlocada
  ) => {
    setCurrentReading(reading);
    setCurrentIda(ida);
    setMeterMacro(principal);
    setMeterAuto(automaton);
    setCurrentCompany(company);
    setQtd(qtd);
    setQtdAlocada(qtdAlocada);
    setShowData(true);
  };
  const atualizarIda = () => {
    setAtualiza("none");
    Swal.fire({
      icon: "sucess",
      text: "Seu pedido foi enviado, aguarde...",
    });

    api
      .get("/hydrometersmastercdmn/updateIda")
      .then((response) => {
        Swal.fire({
          icon: "sucess",
          text: "Dados Atualizados.",
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "sucess",
          text: "Sua requisição ainda está sendo processada, aguarde alguns instantes..",
        });
      });
  };

  const compareIda = (arr, idaProcurada) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].IDA === idaProcurada) {
        return true;
      }
    }
    return false;
  };
  const showDays = async (hidrometro) => {
    setLoad(true);
    await api
      .post("/consumptions/drawcdmn", {
        NR_HIDROMETRO: hidrometro,
        TYPE: "FULL",
      })
      .then((response) => {
        setMes([]);
        setValues([]);
        var k = 0;
        let sumPulse = 0;
        for (var i = 0; i < response.data.length; i++) {
          sumPulse = sumPulse + response.data[i].valor;
          setValues((oldState) => [...oldState, response.data[i].valor]);
          setMes((oldState) => [...oldState, response.data[i].DATA]);

          if (k > 0) {
            var d = response.data[i];
            setData2((oldState) => [...oldState, d]);
          } else {
            var d = response.data[i];
            setData((oldState) => [...oldState, d]);
          }
        }
        setHidrometro(response.data[0].NR_HIDROMETRO_IMOVEL);
        setLocalidade(response.data[0].DS_COMPLEMENTO_IMOVEL);
        setLoad(false);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      });
  };

  const chartData = {
    labels: mes,
    datasets: [
      {
        label: "teste",
        backgroundColor: "#02a499",
        borderColor: "#02a499",
        borderWidth: 1,
        hoverBackgroundColor: "#02a499",
        hoverBorderColor: "#02a499",
        data: values,
        total: sum(values),
      },
    ],
  };
  const option = {
    tootlbar: {
      show: false,
    },

    legend: {
      display: false,
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var total = dataset.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
    onClick: function (event, element) {},
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consumo condomino</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Consumo condomino</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={10}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <label
                      htmlFor="RGI_PRINCIPAL"
                      className="col-md-2 col-form-label"
                    >
                      Principal/Macro medidor
                    </label>

                    <div className="col-md-6">
                      <select
                        name="RGI_PRINCIPAL"
                        className="form-control form-control-lg"
                        {...register("RGI_PRINCIPAL", {
                          required: false,
                        })}
                      >
                        <option value="">Selecione...</option>
                        {macro?.map((elem, key) => (
                          <option
                            key={key}
                            value={elem.CD_LIGACAO_RGI_PRINCIPAL}
                          >
                            {elem.NM_CONDOMINIO
                              ? elem.NM_CONDOMINIO
                              : elem.CD_LIGACAO_RGI_PRINCIPAL}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label htmlFor="NR_IDA" className="col-md-2 col-form-label">
                      IDA
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_IDA", {
                          required: false,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_IDA"
                        defaultValue=""
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    {}
                    <label
                      htmlFor="NR_HIDROMETRO"
                      className="col-md-2 col-form-label"
                    >
                      Número do Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_HIDROMETRO", {
                          required: false,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_HIDROMETRO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="SERVICE"
                      className="col-md-2 col-form-label"
                    >
                      Empresa
                    </label>
                    <div className="col-md-6">
                      <select
                        name="SERVICE"
                        className="form-control form-control-lg"
                        {...register("SERVICE", { required: false })}
                      >
                        <option value="">Selecione...</option>
                        {service?.map((elem, key) => (
                          <option key={key} value={elem.OID_EMPRESA}>
                            {elem.NM_RAZAO_SOCIAL}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DT_FILTER_INI"
                      className="col-md-2 col-form-label"
                    >
                      Data Início
                    </label>
                    {date1.length > 0 ? (
                      <div className="col-md-6">
                        <input
                          {...register("DT_FILTER_INI", { required: false })}
                          className="form-control form-control-lg"
                          type="date"
                          name="DT_FILTER_INI"
                          defaultValue={date1}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DT_FILTER_FIM"
                      className="col-md-2 col-form-label"
                    >
                      Data Final
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("DT_FILTER_FIM", { required: false })}
                        className="form-control form-control-lg"
                        type="date"
                        name="DT_FILTER_FIM"
                        defaultValue={new Date().toISOString().slice(0, 10)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          {showData ? (
            <Col
              lg={3}
              style={{
                position: "fixed",
                top: "80px",
                right: "10px",
                width: "18%",
                minWidth: "300px",
              }}
            >
              <Card>
                <CardBody>
                  <div>
                    <div>
                      <h4 style={{ whiteSpace: "nowrap" }}>
                        Nome do Condôminio
                      </h4>

                      {meterMacro &&
                      macro?.find(
                        (elem) => elem.CD_LIGACAO_RGI_PRINCIPAL === meterMacro
                      )?.NM_CONDOMINIO ? (
                        <div>
                          {macro
                            .filter(
                              (elem) =>
                                elem.CD_LIGACAO_RGI_PRINCIPAL === meterMacro &&
                                elem.NM_CONDOMINIO
                            )
                            .map((elem, key) => (
                              <div key={key}>{elem.NM_CONDOMINIO}</div>
                            ))}
                        </div>
                      ) : (
                        <div>‎</div>
                      )}
                    </div>

                    <div>
                      <h4 style={{ whiteSpace: "nowrap" }}>
                        Principal/Macro medidor
                      </h4>
                    </div>
                    {consumptions.length > 0 ? (
                      <div>{meterMacro}</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <div>
                      <h4>IDA</h4>
                    </div>
                    {consumptions.length > 0 ? (
                      <div>{currentIda}</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <div>
                      <h4>Imovel Autônomo</h4>
                    </div>
                    {consumptions.length > 0 ? (
                      <div>{meterAuto}</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <div>
                      <h4>Leitura Atual</h4>
                    </div>
                    {consumptions.length > 0 ? (
                      <div
                        className="mb-0 font-size-20 h5"
                        style={{ color: "#0057AA" }}
                      >
                        {currentReading != "" ? (
                          <>
                            {" "}
                            <span>
                              {currentReading.toString().slice(0, -3).slice(-4)}
                            </span>
                            <span style={{ color: "red" }}>
                              {currentReading.toString().slice(-3)}{" "}
                            </span>
                            <span>M³</span>
                          </>
                        ) : (
                          <div>‎</div>
                        )}
                      </div>
                    ) : (
                      <div>‎</div>
                    )}
                  </div>
                  <div>
                    <div>
                      <h4>Empresa</h4>
                    </div>
                    {consumptions.length > 0 ? (
                      <div>{currentCompany}</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div>
                    {consumptions.length > 0 ? (
                      <>
                        <div>
                          <b>Apartamentos</b> {qtdAlocada} /{" "}
                          <text style={{ color: "red" }}>
                            {qtd - qtdAlocada}
                          </text>
                        </div>
                        <div>
                          <b>Total: {qtd} Apartamentos</b>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <div className="button-items" style={{ display: atualiza }}>
          <Button color="primary" className="btn" onClick={atualizarIda}>
            Atualizar Dados
          </Button>
          <br></br>
        </div>
        <div style={{ display: showDownload }}>
          <ExcelFile
            filename="Consumo Condominio"
            class="d-flex justify-content-center"
          >
            <ExcelSheet data={dataExcel2} name="Localizações">
              <ExcelColumn label="IDA" value="IDA" />
              <ExcelColumn label="CÓD. HIDROMETRO" value="HIDROMETRO" />
              <ExcelColumn label="CONTA IMOVEL" value="CONTA_IMOVEL" />
              <ExcelColumn
                label="COMPLEMENTO IMOVEL"
                value="COMPLEMENTO_IMOVEL"
              />
              <ExcelColumn label="DATA" value="DATA" />
              <ExcelColumn label="HORA" value="HORA" />
              <ExcelColumn label="PULSO" value="PULSO" />
              <ExcelColumn label="LEITURA LITROS" value="LITROS" />
              <ExcelColumn label="LEITURA ATUAL" value="LEITURA_ATUAL" />
              <ExcelColumn label="MACRO MEDIDOR" value="MACRO" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="table-responsive">
          <table className="table align-middle table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th className="align-middle">IDA</th>
                <th className="align-middle">Cód. Hidrômetro</th>
                <th className="align-middle">Conta Imovel</th>
                <th className="align-middle">Complemento Imovel</th>
                <th className="align-middle">Data</th>
                <th className="align-middle">Hora</th>
                <th className="align-middle">Nr Pulso</th>
                <th className="align-middle">Litros</th>
                <th className="align-middle">Valor de Leitura Litros</th>

                <th className="align-middle">{consumptioDifference}</th>
                <th className="align-middle"></th>
              </tr>
            </thead>
            <tbody>
              {consumptions?.map((consumption, key) => (
                <tr
                  onClick={() => {
                    setSelect(key);
                    console.log(consumption);
                    changeDate(
                      parseInt(consumption.NR_PULSO) +
                        parseInt(consumption.VR_RELOJOARIA_INICIAL),
                      consumption.NR_IDA_IMOVEL,
                      consumption.CD_LIGACAO_RGI_PRINCIPAL,
                      consumption.CD_LIGACAO_RGI_AUTONOMA,
                      consumption.NM_RAZAO_SOCIAL,
                      consumption.QUANTIDADE_TOTAL,
                      consumption.QUANTIDADE_TOTAL_ALOCADA
                    );
                  }}
                  key={"_tr_" + key}
                >
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption.NR_IDA_IMOVEL}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption?.NR_HIDROMETRO_IMOVEL}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption?.NR_CONTA_IMOVEL}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption?.DS_COMPLEMENTO_IMOVEL}
                  </td>

                  <td className={select == key ? "bg-secondary" : ""}>
                    {new Date(consumption.DT_LEITURA).toLocaleDateString()}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {new Date(consumption.DT_LEITURA).toLocaleTimeString()}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption.NR_PULSO}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption.VR_LITROS_POR_PULSO}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumption.VR_LEITURA_LITROS}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {consumptioDifference != ""
                      ? consumption.NR_DIFERENCA_CONSUMO
                      : ""}
                  </td>
                  <td className={select == key ? "bg-secondary" : ""}>
                    {" "}
                    <i
                      class="dripicons-expand"
                      onClick={() => {
                        toggle();
                        setIda(consumption.NR_IDA_IMOVEL);
                        showDays(consumption?.NR_HIDROMETRO_IMOVEL);
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal isOpen={modal} toggle={toggle} size="xl">
          <ModalHeader
            color="primary"
            toggle={toggle}
            className="d-flex justify-content-center page-title"
          >
            {load ? (
              <>Aguarde...</>
            ) : (
              <>
                <h2 className="d-flex justify-content-center page-title">
                  Consumo
                </h2>
                <span className="d-flex justify-content-center page-title">
                  Hidrometro: {hidrometro} IDA:{ida} APTO:{localidade}
                </span>
              </>
            )}
          </ModalHeader>
          <ModalBody>
            {load ? (
              <>...</>
            ) : (
              <>
                <Row className="mb-3">
                  <Bar
                    width={800}
                    height={245}
                    data={chartData}
                    options={option}
                  />
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Consumo2;
