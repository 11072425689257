import { sum } from "lodash";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ReactExport from "react-data-export";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import api from "../../../services/api";

import { composeInitialProps } from "react-i18next";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const initialData = [
  { 
    MES: "",
    MAIORPULSO: "",
    MENORPULSO: "",
    DEVICE: "",
    NR_HIDROMETRO: "",
    IDA: "",
    DS_LOCALIDADE: "",
  },
  {
    MES: "",
    MAIORPULSO: "",
    MENORPULSO: "",
    DEVICE: "",
    NR_HIDROMETRO: "",
    IDA: "",
    DS_LOCALIDADE: "",
  },
];

function BarChart() {
  const [parametroUrl, setParametroUrl] = useState("");
  const location = useLocation();
  const [showInfos, setShowInfos] = useState([]);
  const [showDisplay, setShowDisplay] = useState("none");
  const [showPulse, setShowPulse] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(initialData);
  const [search, setSearch] = useState({
    ida: undefined,
    hydro: undefined,
    lora:undefined,
    dateInit: '',
    dateEnd: '',
  });
  const [typeHidro, setTypeHidro] = useState({
    ativo: 0,
    inativo: 0
  });

  useEffect(() => {
    const queryString = location.search.split("?")[1];

    setParametroUrl(queryString);
    setHidrometro(queryString);
    if (queryString) {
      setSearch({
        ida: undefined,
        hydro: queryString,
      });
      submitHandler(queryString);
    }
  }, [location]);
   

  const [localidade, setLocalidade] = useState([]);
  const [hidrometro, setHidrometro] = useState("");
  const [ida, setIda] = useState("");
  const [values, setValues] = useState([]);
  const [mes, setMes] = useState([]);
  const [day, setDay] = useState(0);
  const [filter, setFilter] = useState(false)
  const [total2, setTotal2] = useState([]);
  const [total, setTotal] = useState("");
  const [titleAlarm, setTitleAlarm] = useState("");
  const [titleAlarmTipe, setTitleAlarmTipe] = useState("");
  const [isMeter, setIsMeter] = useState(false)
  const [isLora, setIsLora] = useState(false)
  const [dataLora, setDataLora] = useState([])
  const [typeEquip, setTypeEquip] = useState("")
  const [showLocation, setShowLocation] = useState("")
  const [searchType,setSearchType] = useState("Dashboard")
  const [meter, setMeter] = useState({
    h_24: 0,
    h_12: 0,
    h_3: 0,
  });
  const [baterys, setBaterys] = useState({
    status100: 0,
    status80: 0,
    status50: 0,
  });
  
  const [alarm, setAlarm] = useState({
    total: 0,
    corte: 0,
    vazamento: 0,
    ataque_magnetico: 0,
    reverso: 0,
  });
  const [loraType, setLoraType] = useState({
    vinculado: 0,
    disponivel: 0
  })
  useEffect(() => {
    alarms();
    meters();
    hidrometerType();
    loras();
    batery();
  }, []);
  function resetSearch() {
    resetStates();
    setShowPulse("");
    setTotal("");
    setSearch({
      ida: "",
      hydro: "",
      lora:"",
      dateInit: '',
      dateEnd: '',

    });
    setSearchType("Dashboard")
  }
  function resetStates() {
    setIsLoading(true);
    setShowDisplay("none");
    setLocalidade([]);
    setHidrometro("");
    setIda("");
    setValues([]);
    setMes([]);
    setValues([]);
    setDay(0)
    setFilter(false)
    setIsLora(false)
  }
  const alarms = async () => {
    await api
      .post("/consumptions/alarm", {
        TYPE: "GERAL",
      })
      .then((response) => {
        let pulso = response.data[0].PULSO_1 + response.data[0].PULSO_2;
        let totalAlarm =
          pulso +
          response.data[0].STATUS_DA_VALVULA +
          response.data[0].ATAQUE_MAGNETICO;

        setAlarm({
          total: totalAlarm,
          corte: pulso,
          vazamento: response.data[0].STATUS_DA_VALVULA,
          ataque_magnetico: response.data[0].ATAQUE_MAGNETICO,
          reverso: response.data[0].REVERSE,
        });
      });
  };

  const meters = async () => {
    await api
      .post("/consumptions/meters", {
        HOUR: "HOUR",
        TYPE: "FULL",
      })
      .then((response) => {
        setMeter({
          h_24: response.data.METERS24,
          h_12: response.data.METERS12,
          h_3: 0
        });
      });
  };
  const loras = async (type='',NR_EQUIPAMENTO="") => {
    await api
      .post("/equipmentslora/linked", {
        NR_EQUIPAMENTO:NR_EQUIPAMENTO,
      })
      .then((response) => {
        if(NR_EQUIPAMENTO ==""){
          setDataLora({
            vinculado: response.data.linked,
            disponivel: response.data.notLinked
          }) 
          setLoraType({
            vinculado: response.data.countLinked,
            disponivel: response.data.countNotLinked
          });
       }else{
          if(type == 'lorasDisponiveis' && response.data.notLinked.length > 0){
            setShowInfos(response.data.notLinked)
          }else if(type == 'lorasVinculados' && response.data.linked.length > 0){
            setShowInfos(response.data.linked)
          }else{
            Swal.fire({
              title: "Nenhum resultado encontrado para esta busca!",
              icon: "warning",
            });
          }
        }
      });

  }
  const batery = async () => {
    await api
      .post("/consumptions/Batery", {
      
        TYPE: "FULL",
      })
      .then((response) => {
        setBaterys({
          status100: response.data.bateria100,
          status80: response.data.bateria80,
          status50: response.data.bateria50
        });
      });
  };
  const hidrometerType = async () => {
    await api
      .post("/consumptions/hidrometer", {
        TYPE: "FULL",
      })
      .then((response) => {
        setTypeHidro({
          ativo: response.data[0].ATIVOS,
          inativo: response.data[0].INATIVOS
        });
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading && showDisplay === 'none') {
        window.location.reload();
      }
    }, 90000);

    return () => {
      clearInterval(interval);
    };
  }, [isLoading, showDisplay]);

  const showMeters = async (filter,hidro="") => {
    await api
      .post("/consumptions/meters", {
        HOUR: filter,
        TYPE: "",
        NR_HIDROMETRO:hidro,
      })
      .then((response) => {
        if(response.data.length > 0){
        setShowInfos(response.data);
        setShowDisplay("block");
        setTitleAlarm("Medidores sem consumo " + filter);
        setSearchType("Medidores" + filter)

        setTitleAlarmTipe("");
        setTypeEquip("Hidrometro")
        setShowLocation("Localização")

        setIsMeter(true)
        setIsLora(false)
      }else{
         Swal.fire({
            title: "Nenhum resultado encontrado para esta busca!",
            icon: "warning",
          });
      }
      });
  };
  const showTypeHidro = async (filter,hidro='') => {
    await api
      .post("/consumptions/hidrometer", {
        TYPE: filter,
        NR_HIDROMETRO:hidro,
      })
      .then((response) => {
        if(response.data.length > 0){
        setShowInfos(response.data);
        setShowDisplay("block");
        setIsMeter(false)
        setIsLora(false)
        setTypeEquip("Hidrometro")
        setShowLocation("Localização")

        if (filter == "ATIVOS") {
          setTitleAlarm("Hidrometros Ativos");
          setSearchType("hidrometrosAtivos")


        } else if (filter == "INATIVOS") {
          setTitleAlarm("Hidrometros Inativos");
          setSearchType("hidrometrosInativos")


        }
        setTitleAlarmTipe("");
      }else{
         Swal.fire({
            title: "Nenhum resultado encontrado para esta busca!",
            icon: "warning",
          });
      }
      });
  };
  const showLoras = async (filter) => {
    setTypeEquip("lora")
    setSearchType("loras")
    setTitleAlarmTipe("")
    if (filter == "vinculados") {
      setSearchType("lorasVinculados")

      setShowInfos(dataLora.vinculado)
      setShowLocation("Localização")
      setTitleAlarm("Loras Vinculados")
    setIsLora(false)


    } else {
      setSearchType("lorasDisponiveis")

      setShowInfos(dataLora.disponivel)
      setTitleAlarm("Loras Disponíveis")
      setIsLora(true)

      setShowLocation("")

    }
    setShowDisplay("block");
    setIsMeter(false)


  }
  const showAlarms = async (filter,hidro='') => {
    await api
      .post("/consumptions/alarm", {
        TYPE: filter,
        NR_HIDROMETRO:hidro,
      })
      .then((response) => {
        if(response.data.length > 0){
        setShowInfos(response.data);
        setShowDisplay("block");
        setIsMeter(false)
        setIsLora(false)
        setTypeEquip("Hidrometro")
        setShowLocation("Localização")


        setTitleAlarm("Alarmes das ultimas 12 horas --> ");
        if (filter == "CORTE") {
          setTitleAlarmTipe("Corte de cabo do Pulso");
          setSearchType("alarmesCorte")

        } else if (filter == "VAZAMENTO") {
          setTitleAlarmTipe("Vazamento no Pulso");
          setSearchType("alarmesVazamento")

        } else if (filter == "ATAQUE") {
          setTitleAlarmTipe("Ataque Magnético");
          setSearchType("alarmesAtaque")

        } else if (filter == "REVERSO") {
          setTitleAlarmTipe("Consumo Reverso");
          setSearchType("alarmesReverso")

        }
      }else{
        Swal.fire({
           title: "Nenhum resultado encontrado para esta busca!",
           icon: "warning",
         });
     }
      });
  };

  const showBatery = async (filter,hidro='') => {
    await api
      .post("/consumptions/batery", {
        TYPE: filter,
        NR_HIDROMETRO:hidro,
      })
      .then((response) => {
        if(response.data.length > 0){
        setShowInfos(response.data);
        setShowDisplay("block");
        setIsMeter(false)
        setIsLora(false)
        setTypeEquip("Hidrometro")
        setShowLocation("Localização")


        if (filter == "100") {
          setTitleAlarm("Baterias de 100% a 80%");
          setSearchType("bateria100")

        } else if (filter == "80") {
          setTitleAlarm("Baterias de 79% a 50%");
          setSearchType("bateria80")

        } else if (filter == "50") {
          setTitleAlarm("Baterias de 49% a 0%");
          setSearchType("bateria50")


        }

        setTitleAlarmTipe("");
      }else{
        Swal.fire({
          title: "Nenhum resultado encontrado para esta busca!",
          icon: "warning",
        });
      }
      });
  };

  const showHidro = async (idHidro) => {
    if (typeEquip == "lora") {
      return
    }

    setHidrometro(idHidro.target.innerHTML);
    setSearch({
      ida: undefined,
      hydro: idHidro.target.innerHTML,
    });
    submitHandler(idHidro.target.innerHTML);
  };

  const submitHandlerPrevent = async (event) => {
    event.preventDefault();
    console.log(searchType);
    console.log(search);

    if(searchType == "Dashboard"){
      submitHandler();
    }else if(searchType == "Medidores24h"){
      showMeters("24h",search.hydro)
    }else if(searchType == "Medidores12h"){
      showMeters("12h",search.hydro)
    }else if(searchType == "hidrometrosAtivos"){
      showTypeHidro("ATIVOS",search.hydro)
    }else if(searchType == "hidrometrosInativos"){
      showTypeHidro("INATIVOS",search.hydro)
    }else if(searchType == "bateria100"){
      showBatery("100",search.hydro)
    }else if(searchType == "bateria80"){
      showBatery("80",search.hydro)
    }else if(searchType == "bateria50"){
      showBatery("50",search.hydro)
    }else if(searchType == "alarmesVazamento"){
      showAlarms("VAZAMENTO",search.hydro)
    }else if(searchType == "alarmesAtaque"){
      showAlarms("ATAQUE",search.hydro)
    }else if(searchType == "alarmesCorte"){
      showAlarms("CORTE",search.hydro)
    }else if(searchType == "alarmesReverso"){
      showAlarms("REVERSO",search.hydro)
    }else if(searchType == "lorasDisponiveis"){
      loras("lorasDisponiveis",search.hydro)
    }else if(searchType == "lorasVinculados"){
      loras("lorasVinculados",search.hydro)
    }

  };
  const submitHandler = async (idHidro) => {
    if (typeof idHidro == 'string') {

      search.hydro = idHidro;
    }
    if (hidrometro !== undefined || ida !== undefined) {

      await api
        .post("/consumptions/draw", {
          NR_HIDROMETRO: day ? hidrometro : search.hydro,
          IDA: search.ida,
          TYPE: "MES",
          DATE: { "start": search.dateInit ? search.dateInit : "", "end": search.dateEnd ? search.dateEnd : "" }
        })
        .then((response) => {
          let oldValue = 0
          var lastMax = 0;
          var k = 0;
          resetStates();
          let sumPulse = 0
          let countMes = "";
          let consumo = 0
          for (var i = 0; i < response.data.length; i++) {
            if (typeof response.data[i].MENORPULSO == "undefined") {
              response.data[i].MENORPULSO = 0;
            }
            response.data[i].MENORPULSO = Math.trunc(
              response.data[i].MENORPULSO
            );
            response.data[i].MAIORPULSO = Math.trunc(
              response.data[i].MAIORPULSO
            );
            consumo =((response.data[i].MAIORPULSO - response.data[i].MENORPULSO) > 0? (response.data[i].MAIORPULSO - response.data[i].MENORPULSO): 0)
            if (i === 0) {
              countMes = response.data[i].MES;
              sumPulse = sumPulse + (response.data[i].MAIORPULSO - response.data[i].MENORPULSO)

              setValues((oldState) => [
                ...oldState,
                consumo ,
              ]);
              lastMax = response.data[i].MAIORPULSO
              setTotal2(Number(response.data[i].DS_RELOJOARIA_INICIAL));

              
            } else {
              const dataFiltered = data.slice(0, 2);
              setData(dataFiltered);

              oldValue = (response.data[i].MENORPULSO - lastMax > 0 ? response.data[i].MENORPULSO - lastMax: 0 )
              sumPulse = sumPulse + (consumo + oldValue)

              setValues((oldState) => [
                ...oldState,
                (consumo+ oldValue),
              ]);
              lastMax = response.data[i].MAIORPULSO
            }

            setMes((oldState) => [...oldState, response.data[i].MES]);


            
              var d = response.data[i];
              setData((oldState) => [...oldState, d]);
              setIsLoading(false);
            
          }
          setTotal(response.data[(response.data.length -1 )].MAIORPULSO );
          setHidrometro(response.data[0].NR_HIDROMETRO);
          setIda(response.data[0].IDA);
          setLocalidade(response.data[0].DS_LOCALIDADE);
          search.dateInit ? setShowPulse(sumPulse) : ""
          search.dateInit ? setDay(1) : ""
          search.dateInit ? setFilter(true) : ""

        })
        .catch((error) => {
          let resp = error.response.data.split('*')
          Swal.fire({
            title: resp[1],
            icon: "warning",
          });
        });

    }
  };
  const showDay = async (filter) => {
    await api
      .post("/consumptions/draw", {
        NR_HIDROMETRO: hidrometro,
        IDA: ida,
        TYPE: "DAY",
        MES: filter,
      })
      .then((response) => {
        let oldValue = 0
        var lastMax = 0
        var k = 0;
        resetStates();
        let sumPulse = 0
        let countMes = "";
        let consumo
        for (var i = 0; i < response.data.length; i++) {
          if (typeof response.data[i].MENORPULSO == "undefined") {
            response.data[i].MENORPULSO = 0;
          }
          response.data[i].MENORPULSO = Math.trunc(response.data[i].MENORPULSO);
          response.data[i].MAIORPULSO = Math.trunc(response.data[i].MAIORPULSO);
          consumo =((response.data[i].MAIORPULSO - response.data[i].MENORPULSO) > 0? (response.data[i].MAIORPULSO - response.data[i].MENORPULSO): 0)

          if (i === 0) {
            response.data[i].MENORPULSO = Math.trunc(
              response.data[i].MENORPULSO
            );
            response.data[i].MAIORPULSO = Math.trunc(
              response.data[i].MAIORPULSO
            );
            countMes = response.data[i].MES;
            sumPulse = sumPulse + consumo
            setValues((oldState) => [
              ...oldState,
              consumo
            ]);
            lastMax = response.data[i].MAIORPULSO
            setTotal2(Number(response.data[i].DS_RELOJOARIA_INICIAL));


          } else {
            const dataFiltered = data.slice(0, 2);
            setData(dataFiltered);
            oldValue = (response.data[i].MENORPULSO - lastMax > 0 ? response.data[i].MENORPULSO - lastMax: 0 )
            sumPulse = sumPulse + (consumo + oldValue)

            setValues((oldState) => [
              ...oldState,
              (consumo + oldValue),
            ]);
            lastMax = response.data[i].MAIORPULSO
          }

          setMes((oldState) => [...oldState, response.data[i].MES]);
         
            var d = response.data[i];
            setData((oldState) => [...oldState, d]);
            setIsLoading(false);
            setDay(1);
            console.log(values)
          
        }
        setHidrometro(response.data[0].NR_HIDROMETRO);
        setIda(response.data[0].IDA);
        setLocalidade(response.data[0].DS_LOCALIDADE);
        search.dateInit ? setShowPulse(sumPulse) : ""
      })
      .catch((error) => {
        let resp = error.response.data.split('*')
        Swal.fire({
          title: resp[1],
          icon: "warning",
        });
      });
  };
  const showHour = async (filter) => {
    await api
      .post("/consumptions/draw", {
        NR_HIDROMETRO: hidrometro,
        IDA: ida,
        TYPE: "HOUR",
        MES: filter,
      })
      .then((response) => {
        var k = 0;
        resetStates();
        let sumPulse = 0
        let countMes = "";
        for (var i = 0; i < response.data.length; i++) {
           
            countMes = response.data[i].MES;
            sumPulse = sumPulse + (response.data[i].valor)
            setValues((oldState) => [
              ...oldState,
              response.data[i].valor,
            ]);
          setMes((oldState) => [...oldState, response.data[i].MES]);
          if (k > 0) {
            var d = response.data[i];
            setData2((oldState) => [...oldState, d]);
          } else {
            var d = response.data[i];
            setData((oldState) => [...oldState, d]);
            setIsLoading(false);
            setDay(2);
          }
        }
        setTotal2(Number(response.data[0].DS_RELOJOARIA_INICIAL));
        setHidrometro(response.data[0].NR_HIDROMETRO);
        setIda(response.data[0].IDA);
        setLocalidade(response.data[0].DS_LOCALIDADE);
       
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "warning",
        });
      });
  };
  const onChangeHandler = (prop) => (event) => {
    setSearch((oldState) => ({ ...oldState, [prop]: event.target.value }));
  };
  const chartData = {
    labels: mes,
    datasets: [
      {
        label: "teste", 
        backgroundColor: "#02a499",
        borderColor: "#02a499",
        borderWidth: 1,
        hoverBackgroundColor: "#02a499",
        hoverBorderColor: "#02a499",
        data: values,
        total: sum(values),
      },
    ],
  };

  const option = {
    tootlbar: {
      show: false,
    },

    legend: {
      display: false,
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var total = dataset.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + " (" + percentage + "%)";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    }, 
    onClick: function (event, element) {
      if (element.length == 0) {
      } else {
        if (day == 0 && filter == false) {
          showDay(element[0]._model.label);
        }if(day == 1){
          showHour(element[0]._model.label)
          
        }
      }
    },
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <form onSubmit={submitHandlerPrevent} name="formDrawn">
                <table className="table table-condensed table-responsive">
                  <tbody>
                    <tr>
                      <td className="col-sm-6 col-centered leftCol">
                        <div className="col-md-6">


                          <Row className="mb-2">
                            <label
                              htmlFor="codigo"
                              className="col-md-12 col-form-label"
                            >
                             {isLora?"Lora":"Hidrômetro"} 
                            </label>
                            <div className="col-md-12">
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                onChange={ onChangeHandler("hydro") }
                                value={search.hydro}
                              />
                            </div>
                          </Row>
                          <Row className="mb-2" style={{display:searchType == "Dashboard"?"block":"none"}}>
                            <label
                              htmlFor="codigo2"
                              className="col-md-12 col-form-label"
                            >
                              IDA
                            </label>
                            <div className="col-md-12">
                              <Input
                                className="form-control form-control-lg"
                                type="text"
                                onChange={onChangeHandler("ida")}
                                value={search.ida}
                              />
                            </div>
                          </Row>
                          <div className="col-md-12 d-flex justify-content-sm-left" >
                            <Row className="mb-2 col-md-6" style={{display:searchType == "Dashboard"?"block":"none"}}>

                              <div className="col-md-12">
                                <label
                                  htmlFor="codigo2"
                                  className="col-md-12 col-form-label"
                                >
                                  Data Início
                                </label>
                                <div className="col-md-10">
                                  <Input
                                    className="form-control form-control-lg"
                                    type="date"
                                    onChange={onChangeHandler("dateInit")}
                                    value={search.dateInit}
                                  />
                                </div>
                              </div>
                            </Row>
                            <Row className="mb-2 col-md-6" style={{display:searchType == "Dashboard"?"block":"none"}}>
                              <div className="col-md-12">
                                <label
                                  htmlFor="codigo2"
                                  className="col-md-6 col-form-label"
                                >
                                  Data Final
                                </label>
                                <div className="col-md-10">
                                  <Input
                                    className="form-control form-control-lg"
                                    type="date"
                                    onChange={onChangeHandler("dateEnd")}
                                    value={search.dateEnd}
                                  />
                                </div>
                              </div>
                            </Row>
                          </div>
                          <div className="col-md-12 d-flex justify-content-sm-center">
                            <Row className="mb-2 col-md-6">

                              <div className="align-items-center">

                                <div>
                                  <h4 className="mb-0 font-size-20">
                                    {total !== "" ? "Leitura Atual" : ""}
                                  </h4>
                                </div>
                                <h5
                                  className="mb-0 font-size-20"
                                  style={{ color: "#0057AA" }}
                                >
                                  {total !== "" ? parseInt(total + total2) + " M³" : ""}
                                </h5>
                              </div>
                            </Row>
                            <Row className="mb-2 col-md-6">

                              <div className="align-items-center col-md-6">

                                <div className="col-md-12">
                                  <h4 className="mb-0 font-size-20">
                                    {showPulse !== "" ? "Consumo Período" : ""}
                                  </h4>
                                </div>
                                <h5
                                  className="mb-0 font-size-20 align-items-center"
                                  style={{ color: "#0057AA" }}
                                >
                                  {showPulse !== "" ? parseInt(showPulse) + " M³" : ""}
                                </h5>
                              </div>
                            </Row>



                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="mb-2">
                  <div className="button-items text-center">
                    <Button color="secondary" className="btn-lg" type="submit">
                      Pesquisar
                      <i className="dripicons-search" />
                    </Button>
                  </div>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isLoading ? (
        <>
          {showDisplay == "none" ? (
            <>
              <div style={{ display: "flex" }}>



                <div style={{ display: "flex" }}>
                  <div
                    className="col-md-12"
                    style={{
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      borderColor: "#F6F6F8",
                      borderRadius: 5,
                      width: 290,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 15,
                        backgroundColor: "#CEEBEF",
                      }}
                    >
                      <div
                        className="font-size-18 col-md-12"
                        style={{ fontWeight: "bold", fontSize: "xx-large" }}
                      >
                        Alarmes das ultimas 12 horas
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                          onClick={() => showAlarms("CORTE")}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Corte de Cabo do Pulso
                          </div>
                          <div><b>{alarm.corte}</b></div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showAlarms("VAZAMENTO")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Possível Vazamento
                          </div>
                          <div><b>{alarm.vazamento}</b></div>
                        </div>
                      </div>



                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showAlarms("ATAQUE")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Ataque Magnético
                          </div>
                          <div><b>{alarm.ataque_magnetico}</b></div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            borderWidth: 0.1,
                            borderStyle: "solid",
                            borderColor: "#F6F6F8",
                            width: 290,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: 15,
                            }}
                            onClick={() => showAlarms("REVERSO")}
                          >
                            <div style={{ fontWeight: "bold", fontSize: "large" }}>Consumo Reverso</div>
                            <div><b>{alarm.reverso}</b> </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div
                  style={{
                    borderWidth: 0.1,
                    borderStyle: "solid",
                    borderColor: "#F6F6F8",
                    borderRadius: 5,
                    width: 290,
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 15,
                      backgroundColor: "#CEEBEF",
                    }}
                  >
                    <div
                      className="font-size-18 col-md-12"
                      style={{ fontWeight: "bold", fontSize: "xx-large", width: 290 }}
                    >
                      Medidores
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        borderWidth: 0.1,
                        borderStyle: "solid",
                        borderColor: "#F6F6F8",
                        width: 290,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 15,
                        }}
                        onClick={() => showMeters("24h")}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "large" }}>Sem Consumo 24 Horas</div>
                        <div><b>{meter.h_24}</b></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        borderWidth: 0.1,
                        borderStyle: "solid",
                        borderColor: "#F6F6F8",
                        width: 290,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 15,
                        }}
                        onClick={() => showMeters("12h")}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "large" }}>Sem Consumo 12 Horas</div>
                        <div><b>{meter.h_12}</b></div>
                      </div>
                    </div>
                  </div>


                </div>




                <div style={{ display: "flex" }}>
                  <div
                    className="col-md-12"
                    style={{
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      borderColor: "#F6F6F8",
                      borderRadius: 5,
                      width: 290,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 15,
                        backgroundColor: "#CEEBEF",
                      }}
                    >
                      <div
                        className="font-size-18 col-md-12"
                        style={{ fontWeight: "bold", fontSize: "xx-large" }}
                      >
                        Hidrometros
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                          onClick={() => showTypeHidro("ATIVOS")}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Ativos
                          </div>
                          <div><b>{typeHidro.ativo}</b></div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showTypeHidro("INATIVOS")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Inativos
                          </div>
                          <div><b>{typeHidro.inativo}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ display: "flex" }}>
                  <div
                    className="col-md-12"
                    style={{
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      borderColor: "#F6F6F8",
                      borderRadius: 5,
                      width: 290,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 15,
                        backgroundColor: "#CEEBEF",
                      }}
                    >
                      <div
                        className="font-size-18 col-md-12"
                        style={{ fontWeight: "bold", fontSize: "xx-large" }}
                      >
                        Loras
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                          onClick={() => showLoras("disponiveis")}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Disponíveis
                          </div>
                          <div><b>{loraType.disponivel}</b></div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showLoras("vinculados")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large" }}>
                            Vinculados
                          </div>
                          <div><b>{loraType.vinculado}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
         <div style={{ display: "flex" }}>
                  <div
                    className="col-md-12"
                    style={{
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      borderColor: "#F6F6F8",
                      borderRadius: 5,
                      width: 290,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 15,
                        backgroundColor: "#CEEBEF",
                      }}
                    >
                      <div
                        className="font-size-18 col-md-12"
                        style={{ fontWeight: "bold", fontSize: "xx-large" }}
                      >
                        Nivel de Baterias
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                          onClick={() => showBatery("100")}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large",color:"#228B22" }}>
                            80% a 100%
                          </div>
                          <div><b>{baterys.status100}</b></div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showBatery("80")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large",color:"#00FF7F" }}>
                            49% a 79%
                          </div>
                          <div><b>{baterys.status80}</b></div>
                        </div>
                      </div>
                       <div
                        style={{
                          borderWidth: 0.1,
                          borderStyle: "solid",
                          borderColor: "#F6F6F8",
                          width: 290,
                        }}
                        onClick={() => showBatery("50")}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 15,
                          }}
                        >
                          <div style={{ fontWeight: "bold", fontSize: "large",color:"#f8722d" }}>
                            0% a 49%
                          </div>
                          <div><b>{baterys.status50}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </>
          ) : (
            <></>
          )}
          <div style={{ display: showDisplay }}>
            <div>
              <span class="font-weight-bold font-size-18 h2 mt-4 card-title">
                {titleAlarm}
              </span>
              <span class="font-weight-normal card-title">
                {titleAlarmTipe}
              </span>
            </div>
            <div class="col-sm-12 mb-2 card-title d-flex justify-content-end">
              <div style={{display: typeEquip == "lora" ? "none":"block" }}  >
              <ExcelFile
                style={{ display: showDisplay }}
                class="d-flex justify-content-center btn"
                filename={titleAlarm+titleAlarmTipe}
              >
                <ExcelSheet data={showInfos} name="Localizações">
                  {showInfos[0]?.HIDROMETRO ?<ExcelColumn label="HIDROMETRO" value="HIDROMETRO" />: <></> }
                  {showInfos[0]?.LOCALIDADE ? <ExcelColumn label="ENDEREÇO" value="LOCALIDADE" />: <></> }
                  {showInfos[0]?.NR_EQUIPAMENTO ? <ExcelColumn label="EQUIPAMENTO" value="NR_EQUIPAMENTO" /> : <></>
                   }
                  {showInfos[0]?.TIPO?<ExcelColumn label="TRAVADO" value="TIPO" /> : <></> }
                  {showInfos[0]?.NR_BATERIA?<ExcelColumn label="NÍVEL DE CARGA" value="NR_BATERIA" /> : <></> }
                  
                </ExcelSheet>
              </ExcelFile>
              </div>

              <button class="btn btn-link ml-2" onClick={resetSearch}>
                Voltar
              </button>
            </div>

            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    {isLora ? <th className="align-middle w-25">Status</th> : ""}
                    <th className="align-middle">{typeEquip}</th> 
                     {(showInfos[0]?.NR_EQUIPAMENTO)?<td>Número Equip. Lora</td> : "" }
                    
                    <th className="align-middle">{showLocation}</th>
                    {isMeter ? <th className="align-middle">Travado</th> : ""}
                    {showInfos[0]?.NR_BATERIA?<td>Nível de carga</td> : "" }


                  </tr>
                </thead>
                <tbody>
                  {showInfos?.map((element, key) => (
                    <tr key={"_tr_" + key}>
                      {isLora ? <td> {element.PULSOS > 0 ? (
                        <span className="badge bg-success">Registrado</span>
                      ) : ( 
                        <span className="badge bg-danger bg-primary">
                          Não Registrado
                        </span>
                      )}</td> : ""}
                      <td onClick={showHidro}>{typeEquip == "Hidrometro" ? element.HIDROMETRO : element.NR_EQUIPAMENTO}</td>
                      {(element.NR_EQUIPAMENTO)?<td>{element.NR_EQUIPAMENTO} </td> : "" }

                      <td>{element.LOCALIDADE} </td>
                      {isMeter ? <td>{element.TIPO} </td> : ""}
                      {element.NR_BATERIA?<td>{element.NR_BATERIA} </td> : "" }

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          {day ? (
            <>
              <div class="col-sm-12 mb-2 card-title d-flex justify-content-end">
                <button class="btn btn-link ml-2" onClick={submitHandler}>
                  Voltar
                </button>
              </div>
            </>
          ) : (
            <>
              <div class="col-sm-12 mb-2 card-title d-flex justify-content-end">
                <button class="btn btn-link ml-2" onClick={resetSearch}>
                  Voltar
                </button>
              </div>
            </>
          )}

          <Row className="justify-content-center">
            <Col sm={4}>
              <div className="text-center">
                <h5 className="mb-0 font-size-20">{ida}</h5>
                <p className="text-muted">I.D.A</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="text-center">
                <h5 className="mb-0 font-size-20">{hidrometro}</h5>
                <p className="text-muted">HIDROMETRO</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="text-center">
                <h5 className="mb-0 font-size-20">{localidade}</h5>
                <p className="text-muted">Endereço</p>
              </div>
            </Col>
          </Row>
          <Bar width={600} height={245} data={chartData} options={option} />
        </>
      )}
    </>
  );
}

export default BarChart;
