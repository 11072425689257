import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

import Swal from "sweetalert2";

//Check API
import api from "../../../services/api";
//I nclude Both Helper File with needed methods
import { postFakeLogin } from "../../../helpers/fakebackend_helper";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  const errorMessage = () => {
    Swal.fire({
      timer: 1500,
      text: "Usuário não autorizado",
    });
  };

  let auth = process.env.REACT_APP_DEFAULTAUTH;
  auth = "jwt";
  //auth = 'fake'
  let username = user.name//.split("@")[0];

  try {
    if (auth === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.name,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (auth === "jwt") {
      let token = "";
      api
        .post("/auth/login/ad", {
          USERNAME: username,
          PASSWORD: user.password,
        })
        .then((resp) => {
          if (resp.data.token) {
            token = resp.data.token;
            localStorage.setItem("authUser", token);
            put(loginSuccess(token));
            history.push("/dashboard");
          } else {
            errorMessage();
          }
        })
        .catch((error) => {
          errorMessage();
        });
    } else if (auth === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.name,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
