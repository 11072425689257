import React from "react";
import MetaTags from "react-meta-tags";

import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
// import chartJs
import BarChart from "../AllCharts/chartjs/barchart";

const ChartjsChart = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IoT DMAE</title>
        </MetaTags>
        <Container fluid={true}>
          <ol className="breadcrumb m-0"></ol>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Dashboard</CardTitle>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChartjsChart;
