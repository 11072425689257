import React, { useState, useEffect, Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Card,
  Alert,
  CardBody,
  Container,
  Row,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const cadastroEquipamentoLora = () => {
  const initialState = {
    maker: "",
    desc: "",
    model: "",
    number: "",
    payload: [],
  };

  const [makers, setMakers] = useState([]);
  const [payloads, setPayloads] = useState([]);
  const [addLora, setAddLora] = useState(initialState);

  const onChangeHandler = (prop) => (event) => {
    if (prop === "maker") {
      getPayloads(event.target.value);
      return;
    }
    if (prop === "payload") {
      let aux = addLora.payload;
      if(event.target.checked) {
        aux.push(event.target.value);
        setAddLora({ ...addLora, payload: aux });
      } else {
        aux = aux.filter(word => word !== event.target.value);
        setAddLora({ ...addLora, payload: aux });
      }
      return;
    };

    setAddLora({ ...addLora, [prop]: event.target.value });
  };

  const getPayloads = (data) => {
    const oid = data;
    api.post("/makerspayloads/filter", {
        OID_FABRICANTE_LORA: oid,
        TP_ATIVO: "S",
      })
      .then((response) => {
        const arr = [];
        response.data.map((elem) => {
          arr.push({
            id: elem.OID_FABRICANTE_LORA_PAYLOAD,
            makerId: elem.OID_FABRICANTE_LORA,
            protocol: elem.DS_PROTOCOLO,
            protocolVersion: elem.NR_PROTOCOLO_VERSAO,
            payload: elem.TP_PAYLOAD,
            base: elem.NR_BASE_DECRIPTACAO,
          });
        });
        setPayloads(arr);
        setAddLora({ ...addLora, maker: data, payload: [] });
      });
  };

  useEffect(() => {
    api.get("/makerslora").then((response) => {
      const arr = [];
      response.data.map((elem) => {
        arr.push({
          id: elem.OID_FABRICANTE_LORA,
          maker: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(arr);
    });
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    const body = {
      OID_FABRICANTE_LORA: addLora.maker,
      DS_DESCRICAO_EQUIPAMENTO: addLora.desc,
      DS_MODELO_EQUIPAMENTO: addLora.model,
      NR_EQUIPAMENTO: addLora.number,
      PAYLOADS: addLora.payload
    }

    api.post("/equipmentslora", body)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Equipamento Lora cadastrado!",
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Confira todos os dados e faça o cadastro completo!",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Fabricante Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/EquipamentoLora">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_FABRICANTE_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Fabricante Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        onChange={onChangeHandler("maker")}
                        className="form-control form-control-lg"
                        required
                      >
                        <option value="">Selecione...</option>
                        {makers?.map((maker, key) => (
                          <option key={key} value={maker.id}>
                            {maker.maker}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_DESCRICAO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Descrição Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_EQUIPAMENTO"
                        value={addLora.desc}
                        onChange={onChangeHandler("desc")}
                        required
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_MODELO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Modelo Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_EQUIPAMENTO"
                        value={addLora.model}
                        onChange={onChangeHandler("model")}
                        required
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="NR_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Número Equipamento
                      <div></div>
                      (MAC ADDRESS)
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_EQUIPAMENTO"
                        value={addLora.number}
                        onChange={onChangeHandler("number")}
                        required
                      />
                    </div>
                  </Row>
                  {payloads?.length > 0 ? (
                    <Row className="mb-3">
                      <label
                        htmlFor="PAYLOADS"
                        className="col-md-2 col-form-label"
                      >
                        Payloads
                      </label>
                      <div className="col-md-6">
                        {payloads?.map((payload, key) => (
                          <div key={payload.id} className="form-check">
                            <input
                              id={payload.id}
                              type="checkbox"
                              className="form-check-input"
                              name="PAYLOADS"
                              value={payload.id}
                              onChange={onChangeHandler("payload")}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={payload.id}
                            >
                              {" "}
                              {payload.protocol} / {payload.protocolVersion} /{" "}
                              {payload.payload} / {payload.base}
                            </Label>
                          </div>
                        ))}
                      </div>
                      <div className="button-items text-center">
                        <Button
                          color="primary"
                          className="btn-lg"
                          type="submit"
                        >
                          Cadastrar <i className="dripicons-document-new" />
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <Alert color="warning">
                      Você precisa cadastrar um payload para esse fabricante
                      primeiro!{" "}
                      <Link to="/cadastro/Payload-cadastro">
                        <label style={{ cursor: "pointer" }}>Clique aqui</label>
                      </Link>{" "}
                      para cadastrar
                    </Alert>
                  )}
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default cadastroEquipamentoLora;
