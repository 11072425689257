import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";


const fabricanteLora = () => {
  const initialState = {
    maker: "",
    contact: "",
    email: "",
    tel: "",
  };

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [makers, setMakers] = useState([]);
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [validDelete,setValidDelete] = useState(false);
  const [loraData,setLoraData]=useState({})
  const toggle = () => setModal(!modal);
  let history = useHistory(); 
  const routeChange = (newRoute) =>{ 
    history.push(newRoute)
  }
  useEffect(() => {
    getServices();
  }, []);

  const getServices = () =>{
    api.get("/makerslora").then((response) => {
      const arr = formatArray(response.data);
      setMakers(arr);
      setValues(arr);
    });
  }
  const checkDelete = (data) =>{
    console.log(data)
    let body = { OID_FABRICANTE_LORA: data.id }
    api.post("/makerslora/checkDelete", body).then((response) => {
      setValidDelete(response.data.valid);
      setLoad(false);
    });
  }
  const deleteService = (id) =>{
    let body = { OID_FABRICANTE_LORA: id }
    api.post("/makerslora/delete", body).then((response) => {
      toggle();
      getServices();
      Swal.fire({
        icon: "success",
        timer: 1000,
      });
    });
  }

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      
      arr.push({
        id: elem.OID_FABRICANTE_LORA,
        maker: elem.DS_FABRICANTE_LORA,
        contact: elem.NM_CONTATO,
        email: elem.DS_EMAIL,
        tel: elem.NR_TELEFONE,
      })
    })
    return arr;
  }

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const body = {
      DS_FABRICANTE_LORA: search.maker,
      NM_CONTATO: search.contact,
      DS_EMAIL: search.email,
      NR_TELEFONE: search.tel
    };

    api.post("/makerslora/filter", body).then((response) => {
        Swal.fire({
          icon: "success",
          timer: 1000,
        });
        setValues(formatArray(response.data));
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Nenhum resultado encontrado, refaça a sua busca!",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Fabricante Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Cadastro - Fabricante Lora</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <Link to="/cadastro/FabricanteLora-cadastro">
                    <Button color="primary" className="btn-lg">
                      Cadastrar <i className="dripicons-document-new" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Fabricante
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("maker")}
                      >
                        <option value="">Selecione...</option>
                        {makers.map((maker, key) => (
                          <option key={key} value={maker.maker}>
                            {maker.maker}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Contato
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NM_CONTATO"
                        value={search.contact}
                        onChange={onChangeHandler("contact")}

                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      E-mail
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_EMAIL"
                        value={search.email}
                        onChange={onChangeHandler("email")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Telefone
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_TELEFONE"
                        value={search.tel}
                        onChange={onChangeHandler("tel")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Fabricante</th>
                  <th className="align-middle">Contato</th>
                  <th className="align-middle">E-mail</th>
                  <th className="align-middle">Telefone</th>
                  <th className="align-middle"></th>
                </tr>
              </thead>
              <tbody>
                {values?.map((maker, key) => (
                  <tr key={"_tr_" + key}>
                    <td>{maker.maker}</td>
                    <td>{maker.contact}</td>
                    <td>{maker.email}</td>
                    <td>{maker.tel}</td>
                    <td>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { routeChange(`/cadastro/fabricantelora-edit/${maker.id}`) }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>

                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => {toggle();checkDelete(maker);setLoraData(maker)}}
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader
              toggle={toggle}
            >
             <h3>Deletar Fabricante Lora</h3>

            </ModalHeader>
            <ModalBody>
        
                {load ?
                  <>Aguarde..</>
                  :
                  <>{validDelete ?
                    <><h3>Deseja deletar a fabricante {loraData.maker}?</h3><br></br>
                      <div className="btn-lg d-flex justify-content-center mt-3"                >
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { deleteService(loraData.id) }}
                        >
                          Confirmar
                        </Button>
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Cancelar
                        </Button>
                      </div></>
                    :
                    <> <h3>Este fabricante possui dados associados.</h3> <br></br>
                       <div className="btn-lg d-flex justify-content-center mt-3"                >
    
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Entendido
                        </Button>
                      </div>
                    </>}
                  </>}

              


            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default fabricanteLora;
