import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Logs = () => {
  const initialState = {
    TP_ALARME: "",
    DS_LOCALIZAÇÃO: "",
    DATA_INICIO: "",
    DATA_FIM: "",


  };
  const pageSize = 50;

  const [hydrometers, setHydrometers] = useState([]);
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);



  const [pagesToShow] = useState(5);

  const getPageNumbers = () => {
    const middlePage = Math.ceil(pagesToShow / 2);
    let startPage = currentPage - middlePage + 1;
    let endPage = currentPage + middlePage - 1;

    if (startPage <= 0) {
      startPage = 1;
      endPage = pagesToShow;
    } else if (endPage > totalPages) {
      startPage = totalPages - pagesToShow + 1;
      endPage = totalPages;
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };



  const newHidros = () => {
    api.post("/logs").then((response) => {
      let arr = [];
      arr = formatArray(response.data);
      setHydrometers(arr);
      setValues(arr);
      setTotalPages(Math.ceil(arr.length / pageSize));

    });
  }
  const handlePagination = ( currentPage) => {
  
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    newHidros();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let empty = false;
    let body = {
      TP_ALARME: search.TP_ALARME,
      DS_LOCALIZAÇÃO: search.DS_LOCALIZAÇÃO,
      DATA_INICIO: search.DATA_INICIO,
      DATA_FIM: search.DATA_FIM
    };

    if (search === initialState) {
      empty = true;
    }

    if (empty) {
      setValues(hydrometers);
      Swal.fire({
        icon: "success",
        timer: 1000,
      });
      setShowList(false);
      return;
    }

    api
      .post("/logs", body)
      .then((response) => {
        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setValues(hydrometers);
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 1000,
          });
          setValues(formatArray(response.data));
          setSearch(initialState);
        }
      })
      .catch(function (error) {
        setValues(hydrometers);
      });
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };
  const showType = (param) => {
    switch (param) {
      case 'INSERIU':
        return "INSERIDO"
        break;
      case "TRAVADO:HIDROMETRO - INSERIU":
        return "TRAVADO - HIDROMETRO"
        break;
      case "TRAVADO:LORA - INSERIU":
        return "TRAVADO - LORA"
        break;
      case "REMOVEU":
        return "NORMALIZADO"
        break;
      case "TRAVADO:LORA - REMOVEU":
        return "NORMALIZADO"
        break;
      case "TRAVADO:HIDROMETRO - REMOVEU":
        return "NORMALIZADO"
        break;
      default:
        return "INSERIU"
      // code block
    }
  }

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {

      arr.push({
        id: elem.OID_EQUIPAMENTO_DMAE,
        tipo: elem.TP_ALARME,
        localizacao: elem.DS_LOCALIZAÇÂO,
        usuario: elem.DS_USU_ALTER,
        data: elem.DT_ULT_ALTER,
        versao: elem.VS_VERSAO,
        alarme: showType(elem.DS_ALARMES),
      });
    });
    return arr;
  };
  const pages = getPageNumbers();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Logs</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Logs</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>

            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmit}>
                  <Row className="mb-3">
                    <div className="col-md-2">
                      <label htmlFor="TP_ALARME" className="">
                        Tipo de alarme
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        name="TP_ALARME"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("TP_ALARME")}
                        value={search.TP_ALARME}
                      >
                        <option value="">Selecione...</option>
                        <option value="Ataque Magnético">Ataque Magnético</option>
                        <option value="Consumo Reverso">Consumo Reverso</option>
                        <option value="Corte de Cabo do Pulso">Corte de Cabo do Pulso</option>
                        <option value="Medidores Sem Consumo 12h">Medidores Sem Consumo 12h</option>
                        <option value="Medidores Sem Consumo 24h">Medidores Sem Consumo 24h</option>
                        <option value="Possível Vazamento">Possível Vazamento</option>

                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Localização
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_HIDROMETRO"
                        value={search.DS_LOCALIZAÇÃO}
                        onChange={onChangeHandler("DS_LOCALIZAÇÃO")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DT_FILTER_INI"
                      className="col-md-2 col-form-label"
                    >
                      Data Início
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="date"
                        name="DATA_INICIO"
                        value={search.DATA_INICIO}
                        onChange={onChangeHandler("DATA_INICIO")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DATA_FIM"
                      className="col-md-2 col-form-label"

                    >
                      Data Final
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="date"
                        name="DT_FILTER_FIM"
                        defaultValue=""
                        value={search.DATA_FIM}
                        onChange={onChangeHandler("DATA_FIM")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar
                        <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <ExcelFile filename='Logs_Alarmes'>
            <ExcelSheet data={values} name="Logs">
              <ExcelColumn label="Tipo" value="tipo" />
              <ExcelColumn label="Localidade" value="localizacao" />
              <ExcelColumn label="Usuario" value="usuario" />
              <ExcelColumn label="Gravação" value="data" />
              <ExcelColumn label="Alarme" value="alarme" />
            </ExcelSheet>
          </ExcelFile>

          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Tipo</th>
                  <th className="align-middle">Localidade</th>
                  <th className="align-middle">Usuário</th>
                  <th className="align-middle">Gravação</th>
                  <th className="align-middle">Alarme</th>
                </tr>
              </thead>
              <tbody>
                {values
                  .slice((currentPage -1) * pageSize, (currentPage) * pageSize)
                  .map((logs, key) => (
                    <tr key={"_tr_" + key}>
                      <td>{logs.tipo} </td>
                      <td>{logs.localizacao} </td>
                      <td>{logs.usuario}</td>
                      <td>{logs.data}</td>
                      <td>{logs.alarme}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={() => handlePagination(currentPage - 1)} />
                </PaginationItem>
                {currentPage > pagesToShow && (
                  <>
                    <PaginationItem>
                      <PaginationLink onClick={() => handlePagination(1)}>1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                      <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                  </>
                )}
                {pages.map(page => (
                  <PaginationItem key={page} active={currentPage === page}>
                    <PaginationLink onClick={() => handlePagination(page)}>{page}</PaginationLink>
                  </PaginationItem>
                ))}
                {currentPage + pagesToShow <= totalPages && (
                  <>
                    <PaginationItem disabled>
                      <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink onClick={() => handlePagination(totalPages)}>{totalPages}</PaginationLink>
                    </PaginationItem>
                  </>
                )}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={() => handlePagination(currentPage + 1)} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logs;
