import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Card, CardBody, Container, Row, Input, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import Swal from "sweetalert2";

const Payload = () => {
  const initialState = {
    maker: "",
    protocol: "",
    protocolVersion: "",
    payloadType: "",
    base: "",
  };

  const [values, setValues] = useState([]);
  const [makers, setMakers] = useState([]);
  const [search, setSearch] = useState(initialState);

  const [showList, setShowList] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedPayload,setSelectedPayload] = useState(0);
  const toggle = () => setModal(!modal);
  let history = useHistory(); 
  const routeChange = (newRoute) =>{ 
    history.push(newRoute)
  }
  useEffect(() => {
    let maker = [];
    api.get("/makerslora").then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_FABRICANTE_LORA,
          maker: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(maker);
    });
  }, []);

  useEffect(() => {
    getPayloads()
  }, [makers]);

  const getPayloads = () => {
    let payload = [];
    api.get("/makerspayloads").then((response) => {
      payload = formatArray(response.data);
      setValues(payload);
      setShowList(true);

    });
  }
    
  

  const formatArray = (data) => {
    let array = [];
    data.map((elem) => {
      makers.map((elem2) => {
        if (elem.OID_FABRICANTE_LORA === elem2.id) {
          array.push({
            makerId: elem2.id,
            makerName: elem2.maker,
            id: elem.OID_FABRICANTE_LORA_PAYLOAD,
            maker: elem2.maker,
            protocol: elem.DS_PROTOCOLO,
            protocolVersion: elem.NR_PROTOCOLO_VERSAO,
            payloadType: elem.TP_PAYLOAD,
            base: elem.NR_BASE_DECRIPTACAO,
            ativo: elem.TP_ATIVO,
          });
        }
      });
    });
    return array;
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const buttonHandler = (event) => {
    event.preventDefault();
    const aux = search.maker;
    setSearch({...initialState, maker: aux});
    setShowList(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    let body = {
      OID_FABRICANTE_LORA: search.maker,
      DS_PROTOCOLO: search.protocol,
      NR_PROTOCOLO_VERSAO: search.protocolVersion,
      TP_PAYLOAD: search.payloadType,
      NR_BASE_DECRIPTACAO: search.base,
    };


    api
      .post("/makerspayloads/filter", body)
      .then((response) => {
        const arr = formatArray(response.data);
        const aux = search.maker;
        if (arr.length === 0) {
          makers.map((maker) => {
            Swal.fire({
              icon: "warning",
              text: "Nenhum resultado encontrado, refaça a sua busca!",
            });
          });
          setShowList(false);
        } else {
            Swal.fire({
              icon: "success",
              timer: 500,
            });
            setShowList(true);
            setValues(arr);
            setSearch({...initialState, maker: aux});
        }
      })
      .catch(function (error) {
        if (showList) {
          setShowList(false);
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          return;
        }
        Swal.fire({
          icon: "warning",
          text: "Por favor preencha ao menos um campo",
        });
        setShowList(false);
      });
  };
  const deletePayload = (id_payload) =>{
    let body = {
      OID_FABRICANTE_LORA_PAYLOAD: id_payload
    }
    api.post("/makerspayloads/delete",body).then((response) => {
      Swal.fire({
        icon: "success",
        timer: 2000,
        text: "Payload deletado",
      });
      getPayloads()

    }) .catch(function (error) {
        Swal.fire({
        icon: "warning",
        text: "Não foi possível fazer a exclusão, por favor contate o administrador!",
      });
      setShowList(false);
    });
    toggle();
  
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Payload</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Payload</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <Link to={"/cadastro/Payload-cadastro"}>
                    <Button color="primary" className="btn-lg">
                      Cadastrar <i className="dripicons-document-new" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Fabricante Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("maker")}
                      >
                        <option value="">Selecione...</option>
                        {makers.map((maker, key) => (
                          <option key={key} value={maker.id}>
                            {maker.maker}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Protocolo
                    </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="text"
                        id="DS_PROTOCOLO"
                        value={search.protocol}
                        onChange={onChangeHandler("protocol")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Versão do Protocolo
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        id="NR_PROTOCOLO_VERSAO"
                        value={search.protocolVersion}
                        onChange={onChangeHandler("protocolVersion")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Tipo Payload
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        id="TP_PAYLOAD"
                        value={search.payloadType}
                        onChange={onChangeHandler("payloadType")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Base Criptografia
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        id="NR_BASE_DECRIPTACAO"
                        value={search.base}
                        onChange={onChangeHandler("base")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      {showList ? (
                        <Button
                          onClick={buttonHandler}
                          color="secondary"
                          className="btn-lg"
                          style={{ marginRight: "1rem" }}
                        >
                          Limpar Filtros
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Fabricante</th>
                  <th className="align-middle">Protocolo</th>
                  <th className="align-middle">Versão do Protocolo</th>
                  <th className="align-middle">Tipo Payload</th>
                  <th className="align-middle">Base Criptografia</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle"></th>

                </tr>
              </thead>
              <tbody>
                {showList ? (
                  values?.map((elem, key) => (
                    <tr key={"_tr_" + elem.id}>
                      <td>{elem.maker}</td>
                      <td>{elem.protocol}</td>
                      <td>{elem.protocolVersion}</td>
                      <td>{elem.payloadType}</td>
                      <td>{elem.base}</td>
                      <td>
                        {elem.ativo == "S" ? (
                          <span className="badge bg-success">Ativo</span>
                        ) : (
                          <span className="badge bg-danger bg-primary">
                            Inativo
                          </span>
                        )}
                      </td>
                      <td>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { routeChange(`/cadastro/Payload-edit/${elem.id}`) }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {  toggle();setSelectedPayload(elem.id); }}
                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            {!showList ? (
              <label style={{ display: "flex", justifyContent: "center" }}>
                Faça uma pesquisa para obter os resultados
              </label>
            ) : (
              <></>
            )}
          </div>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader color="primary" toggle={toggle} className="d-flex justify-content-center page-title"><h2>Deseja deletar o Payload?</h2><span className="d-flex justify-content-center page-title"> </span> </ModalHeader>
            <ModalBody>
              <Row className="mb-3">

                <div className="btn-lg d-flex justify-content-center mt-3"                >
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { deletePayload(selectedPayload) }}
                  >
                    Deletar
                  </Button>
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { toggle() }}
                  >
                    Cancelar
                  </Button>
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Payload;
