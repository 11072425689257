import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";

import ReactExport from "react-data-export";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const usuarioEmpresaListar = () => {
  const initialState = {
    cnpj: "",
    razao_social: "",

  };

  const [services, setServices] = useState([]);
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [validDelete, setValidDelete] = useState(true);

  const [showList, setShowList] = useState(false);
  const [modalType, setModalType] = useState("")
  const [userData, setUserData] = useState({})
  const [dataEdit, setDataEdit] = useState({
    id_service: "",
    id_user: "",
    cnpj: "",
    razao_social: "",
    telefone: "",
    cep: "",
    logradouro: "",
    bairro: "",
    numero: "",
    cidade: "",
    estado: "",
    responsavel: "",
    email: ""
  })
  const toggle = () => setModal(!modal);

  useEffect(() => {
    getServices()
  }, []);

  const getServices = () => {
    api.post("/empresaservice/empresas").then((response) => {
      let arr = [];
      arr = formatArray(response.data);
      setServices(arr);
      setValues(arr);
    });
  }
  const validateDeletion = (data) => {
    let body = { OID_EMPRESA: data.id_service }
    api.post("/empresaservice/checkDelete", body).then((response) => {
      console.log(response.data.valid);
      setValidDelete(response.data.valid);
      setLoad(false);
    });

  }
  const deleteService = (data) => {
    let body = { OID_EMPRESA: data }
    api.post("/empresaservice/deleteService", body).then((response) => {
      console.log(response.data);
      toggle();
      getServices();
      Swal.fire({
        icon: "success",
        timer: 1000,
      });
    });

  }
  const onSubmit = (event) => {
    event.preventDefault();
    let empty = false;
    let body = {
      NR_CNPJ: search.cnpj,
      DS_RAZAO_SOCIAL: search.razao_social
    };

    if (search === initialState) {
      empty = true;
    }

    if (empty) {
      setValues(services);
      Swal.fire({
        icon: "success",
        timer: 1000,
      });
      setShowList(false);
      return;
    }

    api
      .post("empresaservice/empresas", body)
      .then((response) => {
        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setValues(services);
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 1000,
          });
          setValues(formatArray(response.data));
          setSearch(initialState);
        }
      })
      .catch(function (error) {
        setValues(services);
      });
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const onChangeData = (prop) => (event) => {
    setDataEdit({ ...dataEdit, [prop]: event.target.value });
  };
  const confirmEdit = () => {
    let body = {
      OID_USUARIO: dataEdit.id_user,
      OID_EMPRESA: dataEdit.id_service,
      NR_CNPJ: dataEdit.cnpj,
      DS_RAZAO_SOCIAL: dataEdit.razao_social,
      DS_TELEFONE: dataEdit.telefone,
      NR_CEP: dataEdit.cep,
      DS_LOGRADOURO: dataEdit.logradouro,
      DS_BAIRRO: dataEdit.bairro,
      NR_NUMERO: dataEdit.numero,
      DS_CIDADE: dataEdit.cidade,
      DS_ESTADO: dataEdit.estado,
      USER_NAME: dataEdit.responsavel,
      DS_EMAIL: dataEdit.email

    }

    api
      .post("empresaservice/edit", body)
      .then((response) => {
        if (response.data === 'sucesso') {

          Swal.fire({
            icon: "success",
            text: "Editado com sucesso!",
            timer: 3000,
          });
          toggle();
          getServices();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error)
      });

  }
  const sendEmail = () => {
    let body = { id_user: dataEdit.id_user }
    api
      .post("empresaservice/mail", body)
      .then((response) => {
        if (response.data === 'Enviado') {

          Swal.fire({
            icon: "success",
            text: "Dados enviados para o email cadastrado",
            timer: 1000,
          });
          toggle();
        } else {
          Swal.fire({
            icon: "warning",
            text: "Ocorreu um erro ao enviar o Email, favor contate o administrador!",
          });
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        id_service: elem.OID_EMPRESA,
        id_user: elem.OID_USUARIO,
        cnpj: elem.NR_CNPJ,
        razao_social: elem.NM_RAZAO_SOCIAL,
        telefone: elem.NR_TELEFONE,
        cep: elem.CD_CEP,
        logradouro: elem.DS_LOGRADOURO,
        bairro: elem.DS_BAIRRO,
        numero: elem.NR_LOGRADOURO,
        cidade: elem.NM_CIDADE,
        estado: elem.CD_ESTADO,
        responsavel: elem.NOME,
        email: elem.DS_EMAIL,
      });
    });
    return arr;
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Usuários e Empresas</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Usuário e Empresa</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmit}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Razão Social
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NM_RAZAO_SOCIAL"
                        value={search.razao_social}
                        onChange={onChangeHandler("razao_social")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      CNPJ
                    </label>
                    <div className="col-md-6">
                      <ReactInputMask
                        name="NR_CNPJ"
                        mask="99.999.999/9999-99"
                        className="form-control form-control-lg"
                        type="text"
                        onChange={onChangeHandler("cnpj")}
                        value={search.cnpj}
                      />

                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar
                        <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <ExcelFile filename ='Usuario-Empresa'>
            <ExcelSheet data={values} name="Hidrômetros">
              <ExcelColumn label="Número Hidrômetro" value="number" />
              <ExcelColumn label="Tipo" value="tipo" />
              <ExcelColumn label="Modelo" value="model" />
              <ExcelColumn label="Descrição" value="desc" />
              <ExcelColumn label="Consumo" value="consumption" />
              <ExcelColumn label="Vazão" value="vazao" />
              <ExcelColumn
                label="Status"
                value={(status) => (status ? "Ativo" : "Inativo")}
              />
            </ExcelSheet>
          </ExcelFile>

          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Razão Social</th>
                  <th className="align-middle">CNPJ</th>
                  <th className="align-middle">Telefone</th>
                  <th className="align-middle">Cep</th>
                  <th className="align-middle">Endereço</th>
                  <th className="align-middle">Bairro</th>
                  <th className="align-middle">Número</th>
                  <th className="align-middle">Cidade</th>
                  <th className="align-middle">Usuário</th>
                  <th className="align-middle">Email</th>
                  <th className="align-middle"></th>


                </tr>
              </thead>
              <tbody>
                {values?.map((service, key) => (
                  <tr key={"_tr_" + key}>
                    <td>{service.razao_social}</td>
                    <td>{service.cnpj} </td>
                    <td>{service.telefone}</td>
                    <td>{service.cep}</td>
                    <td>{service.logradouro}</td>
                    <td>{service.bairro}</td>
                    <td>{service.numero}</td>
                    <td>{service.cidade} - {service.estado}</td>
                    <td>{service.responsavel}</td>
                    <td>{service.email}</td>
                    <td>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { setModalType("edit"); toggle(); setUserData(service); setDataEdit(service); }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { setModalType("email"); toggle(); setUserData(service); }}
                      >
                        <i className="dripicons-mail" />
                      </Button>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { setModalType("deletar"); toggle(); setUserData(service); validateDeletion(service); }}
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader
              toggle={toggle}
            >
              {modalType == 'edit' ? <>Editar Usuário/Empresa</> : <></>}
              {modalType == 'email' ? <>Reenviar Email</> : <></>}
              {modalType == 'deletar' ? <>Deletar Usuário/Empresa</> : <></>}

            </ModalHeader>
            <ModalBody>{modalType == 'edit' ?
              <>
                <div className>
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="RAZAO_SOCIAL"
                      className="col-md-12 col-form-label"

                    >
                      Razão Social
                    </label>
                    <div className="col-md-12">
                      <input
                        className="form-control form-control-lg bg-light"
                        type="text"
                        name="RAZAO_SOCIAL"
                        onChange={onChangeData("razao_social")}
                        defaultValue={userData.razao_social}
                        readOnly
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="CNPJ"
                      className="col-md-12 col-form-label"
                    >
                      CNPJ
                    </label>
                    <div className="col-md-12">
                      <ReactInputMask
                        name="CNPJ"
                        mask="99.999.999/9999-99"
                        className="form-control form-control-lg bg-light"
                        type="text"
                        onChange={onChangeData("cnpj")}
                        defaultValue={userData.cnpj}
                        readOnly
                      />

                    </div>
                  </Row>
                  <div className="col-md-12 d-flex ">
                    <Row className="mb-3 col-md-6">
                      { }
                      <label
                        htmlFor="fone"
                        className="col-md-12 col-form-label"
                      >
                        Telefone
                      </label>
                      <div className="col-md-12">

                        <ReactInputMask
                          mask="(99)99999-9999"
                          className="form-control form-control-lg bg-light"
                          type="text"
                          onChange={onChangeData("telefone")}
                          defaultValue={userData.telefone}
                          readOnly
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 col-md-6">
                      { }
                      <label
                        htmlFor="CEP"
                        className="col-md-12 col-form-label"
                      >
                        CEP
                      </label>
                      <div className="col-md-12">

                        <ReactInputMask
                          mask="99999-999"
                          className="form-control form-control-lg bg-light"
                          type="text"
                          onChange={onChangeData("cep")}
                          defaultValue={userData.cep}
                          readOnly />
                      </div>
                    </Row>
                  </div>
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="endereco"
                      className="col-md-12 col-form-label"
                    >
                      Endereço
                    </label>
                    <div className="col-md-12">
                      <input
                        className="form-control form-control-lg bg-light"
                        type="text"
                        name="endereco"
                        onChange={onChangeData("logradouro")}
                        defaultValue={userData.logradouro}
                        readOnly
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="bairro"
                      className="col-md-12 col-form-label"
                    >
                      Bairro
                    </label>
                    <div className="col-md-12">
                      <input
                        className="form-control form-control-lg bg-light"
                        type="text"
                        name="bairro"
                        onChange={onChangeData("bairro")}
                        defaultValue={userData.bairro}
                        readOnly
                      />
                    </div>
                  </Row>
                  <div className="col-md-12 d-flex ">
                    <Row className="mb-3 col-md-6">
                      { }
                      <label
                        htmlFor="numero"
                        className="col-md-12 col-form-label"
                      >
                        Número
                      </label>
                      <div className="col-md-12">
                        <input
                          className="form-control form-control-lg bg-light"
                          type="text"
                          name="numero"
                          onChange={onChangeData("numero")}
                          defaultValue={userData.numero}
                          readOnly

                        />
                      </div>
                    </Row>
                    <Row className="mb-3 col-md-6">
                      { }
                      <label
                        htmlFor="cidade"
                        className="col-md-12 col-form-label"
                      >
                        Cidade
                      </label>
                      <div className="col-md-12">
                        <input
                          className="form-control form-control-lg bg-light"
                          type="text"
                          name="cidade"
                          onChange={onChangeData("cidade")}
                          defaultValue={userData.cidade}
                          readOnly

                        />
                      </div>
                    </Row>
                  </div>
                  {/* <Row className="mb-3">
                    { }
                    <label
                      htmlFor="responsavel"
                      className="col-md-12 col-form-label"
                    >
                      Usuário
                    </label>
                    <div className="col-md-12">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="responsavel"
                        onChange={onChangeData("responsavel")}
                        defaultValue={userData.responsavel}
                      />
                    </div>
                </Row> */}
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="email"
                      className="col-md-12 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-md-12">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="email"
                        onChange={onChangeData("email")}
                        defaultValue={userData.email}
                      />
                    </div>
                  </Row>
                  <div className="btn-lg d-flex justify-content-center mt-3"                >
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { confirmEdit() }}
                    >
                      Confirmar
                    </Button>
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { toggle() }}
                    >
                      Cancelar
                    </Button>
                  </div>

                </div>
              </> : <></>}
              {modalType == 'email' ?
                <>
                  <h3>Deseja Reenviar o email com o client_id e secret_id do usuário {userData.responsavel}?</h3>
                  <div className="btn-lg d-flex justify-content-center mt-3"                >
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { sendEmail() }}
                    >
                      Confirmar
                    </Button>
                    <Button
                      color="primary"
                      className="btn-lg mx-2"
                      type="submit"
                      onClick={() => { toggle() }}
                    >
                      Cancelar
                    </Button>
                  </div>


                </> : <></>
              }
              {modalType == 'deletar' ?
                <>{load ?
                  <>Aguarde..</>
                  :
                  <>{validDelete ?
                    <><h3>Deseja deletar a empresa {userData.razao_social}?</h3><br></br>
                      <div className="btn-lg d-flex justify-content-center mt-3"                >
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { deleteService(userData.id_service) }}
                        >
                          Confirmar
                        </Button>
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Cancelar
                        </Button>
                      </div></>
                    :
                    <> <h3>Esta Empresa possuir HD Individualizados cadastrados </h3> <br></br>
                       <div className="btn-lg d-flex justify-content-center mt-3"                >
    
                        <Button
                          color="primary"
                          className="btn-lg mx-2"
                          type="submit"
                          onClick={() => { toggle() }}
                        >
                          Entendido
                        </Button>
                      </div>
                    </>}
                  </>}

                </>

                :
                <></>}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default usuarioEmpresaListar;
