import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EquipamentoDmae = () => {
  const initialSearchState = {
    hydroNumber: "",
    equipLORA: "",
    location: "",
    region: "",
    locationType: "",
  };

  const [search, setSearch] = useState(initialSearchState);
  const [equipSearch, setEquipSearch] = useState(0);
  const [equipLoc, setEquipLoc] = useState(0);
  const [equipmentsLORA, setEquipmentsLORA] = useState([]);
  const [hydrometers, setHydrometers] = useState([]);
  const [equipmentsDMAE, setEquipmentsDMAE] = useState([]);
  const [values, setValues] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  let history = useHistory(); 
  const routeChange = (newRoute) =>{ 
    history.push(newRoute)
  }

  useEffect(() => {
    api.get("/hydrometers").then((response) => {
      let arr = [];
      response.data.map((elem) => {
        arr.push({
          hydroId: elem.OID_HIDROMETRO,
          hydroNumber: elem.NR_HIDROMETRO,
        });
      });
      setHydrometers(arr);
    });
  }, []);

  useEffect(() => {
    api.get("/equipmentslora").then((response) => {
      let arr = [];
      response.data.map((elem) => {
        arr.push({
          loraId: elem.OID_EQUIPAMENTO_LORA,
          loraDesc: elem.DS_DESCRICAO_EQUIPAMENTO,
          loraNumber: elem.NR_EQUIPAMENTO,
          vinculado: elem.VINCULADO,
        });
      });
      setEquipmentsLORA(arr);
    });
  }, [hydrometers]);

  useEffect(() => {
    api.get("/equipmentsdmae").then((response) => {
      let arr = [];
      arr = formatArray(response.data);
      setEquipmentsDMAE(arr);
      setValues(arr);
    });
  }, [equipmentsLORA]);

  const confirmDelete = () => {
    api.put(`/equipmentsdmae/delete/${equipSearch}`).then((response) => {
      toggle();
      Swal.fire({
        icon: "success",
        text: "Equipamento Deletado",
        timer: 2000,
      });
      api.get("/equipmentsdmae").then((response) => {
        let arr = [];
        arr = formatArray(response.data);
        setEquipmentsDMAE(arr);
        setValues(arr);
      });
    }).catch((error) => {
      Swal.fire({
        icon: "warning",
        text: "Não foi possível fazer a exclusão, por favor contate o administrador!",
      });
    })

  }
  const onSubmit = (event) => {
    event.preventDefault();
    let body = {
      OID_HIDROMETRO: search.hydroNumber,
      OID_EQUIPAMENTO_LORA: search.equipLORA,
      DS_LOCALIDADE: search.location,
      DS_LOCALIZACAO_REGIAO: search.region,
      TP_LOCALIZACAO: search.locationType,
    };

    api
      .post("/equipmentsdmae/filter", body)
      .then((response) => {
        const arr = formatArray(response.data)
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setValues(equipmentsDMAE);
          return;
        }
        Swal.fire({
          icon: "success",
          timer: 1000,
        });

        setValues(arr);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "success",
          timer: 500,
        });
        setValues(equipmentsDMAE);
      });
  };

  const formatArray = (data) => {
    let arr = [];
    let install = ''
    data.map((dmae) => {
      arr.push({
        lora: dmae.NR_EQUIPAMENTO,
        hydro: dmae.NR_HIDROMETRO,
        id: dmae.OID_EQUIPAMENTO_DMAE,
        location: dmae.DS_LOCALIDADE,
        region: dmae.DS_LOCALIZACAO_REGIAO,
        longitude: dmae.DS_LOCALIZACAO_LONGITUDE,
        latitude: dmae.DS_LOCALIZACAO_LATITUDE,
        instalation: new Date(dmae.DT_INST_EQUIP_DMAE).toLocaleDateString() + ' ' + new Date(dmae.DT_INST_EQUIP_DMAE).toLocaleTimeString(),
        alteration: new Date(dmae.DT_ULT_ALTER).toLocaleDateString() + ' ' + new Date(dmae.DT_ULT_ALTER).toLocaleTimeString(),
        locationType: dmae.TP_LOCALIZACAO,
        initialClock: dmae.DS_RELOJOARIA_INICIAL,
      });
    });
    return arr;
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Equipamento Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Equipamento DMAE</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <Link to="/cadastro/EquipamentoDmae-cadastro">
                    <Button color="primary" className="btn-lg">
                      Cadastrar
                      <i className="dripicons-document-new" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmit}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_HIDROMETRO"
                      className="col-md-2 col-form-label"
                    >
                      Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_HIDROMETRO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("hydroNumber")}
                      >
                        <option value="">Selecione...</option>
                        {hydrometers?.map((hydrometer, key) => (
                          <option key={key} value={hydrometer.hydroId}>
                            {hydrometer.hydroNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_EQUIPAMENTO_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Equipamento Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_EQUIPAMENTO_LORA"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("equipLORA")}
                      >
                        <option value="">Selecione...</option>
                        {equipmentsLORA?.map((equip, key) => (
                          equip.vinculado > 0 ? <>
                          <option key={key} value={equip.loraId}>
                            {equip.loraNumber}
                          </option></>:<></>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIDADE"
                      className="col-md-2 col-form-label"
                    >
                      Localidade
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIDADE"
                        value={search.location}
                        onChange={onChangeHandler("location")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_REGIAO"
                      className="col-md-2 col-form-label"
                    >
                      Região
                    </label>
                    <div className="col-md-6">
                      <select
                        name="DS_LOCALIZACAO_REGIAO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("region")}
                      >
                        <option value="">Selecione...</option>
                        <option value="NORTE">Norte</option>
                        <option value="LESTE">Leste</option>
                        <option value="OESTE">Oeste</option>
                        <option value="SUL">Sul</option>
                        <option value="CENTRAL">Central</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="TP_LOCALIZACAO"
                      className="col-md-2 col-form-label"
                    >
                      Tipo de Localização
                    </label>
                    <div className="col-md-6">
                      <select
                        name="TP_LOCALIZACAO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("locationType")}
                      >
                        <option value="">Selecione...</option>
                        <option value="F">Final</option>
                        <option value="T">Temporária</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar
                        <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>

          <ExcelFile filename ='Equipamento DMAE'>
            <ExcelSheet data={values} name="Localizações">
              <ExcelColumn label="Hidrômetro" value="hydro" />
              <ExcelColumn label="Número Equip. Lora" value="lora" />
              <ExcelColumn label="Localização" value="location" />
              <ExcelColumn label="Região" value="region" />
              {/*<ExcelColumn label="Longitude" value="longitude" />
              <ExcelColumn label="Latitute" value="latitude" />*/}
              <ExcelColumn label="Data de Instalação" value="instalation" />
              <ExcelColumn label="Data de Alteração" value="alteration" />

              <ExcelColumn label="Tipo" value="locationType" />
              <ExcelColumn label="Reloj. Inic." value="initialClock" />
            </ExcelSheet>
          </ExcelFile>

          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Hidrômetro</th>
                  <th className="align-middle">Número Equip. Lora</th>
                  <th className="align-middle">Localização</th>
                  <th className="align-middle">Região</th>
                  {/*<th className="align-middle">Longitude</th>
                  <th className="align-middle">Latitute</th>*/}
                  <th className="align-middle">Data de Instalação</th>
                  <th className="align-middle">Data de Alteração</th>

                  <th className="align-middle">Tipo</th>
                  <th className="align-middle">Reloj. Inic.</th>
                  <th className="align-middle"></th>

                </tr>
              </thead>
              <tbody>
                {values?.map((equipDMAE, key) => (
                  <tr key={"_tr_" + key}>
                    <td>
                      <span key={key}>
                        {equipDMAE.hydro}
                      </span>
                    </td>
                    <td>
                      <span key={key}>
                        {equipDMAE.lora}
                      </span>
                    </td>
                    <td>{equipDMAE.location}</td>
                    <td>{equipDMAE.region}</td>
                     {/*<td>{equipDMAE.longitude}</td>
                    <td>{equipDMAE.latitude}</td>*/}
                    <td>{equipDMAE.instalation}</td>
                    <td>{equipDMAE.alteration}</td>

                    <td>{equipDMAE.locationType}</td>
                    <td>{equipDMAE.initialClock}</td>
                    <td>
                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { routeChange(`/cadastro/EquipamentoDmae-edit/${equipDMAE.id}`) }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => { setEquipSearch(equipDMAE.id); setEquipLoc(equipDMAE.location); toggle(); }}
                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader color="primary" toggle={toggle} className="d-flex justify-content-center page-title"><h2>Deseja deletar equipamento da localização:</h2><span className="d-flex justify-content-center page-title"> {equipLoc}</span> </ModalHeader>
            <ModalBody>
              <Row className="mb-3">

                <div className="btn-lg d-flex justify-content-center mt-3"                >
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { confirmDelete() }}
                  >
                    Deletar
                  </Button>
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { toggle() }}
                  >
                    Cancelar
                  </Button>
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EquipamentoDmae;
