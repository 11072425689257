import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import MetaTags from "react-meta-tags";
import { Button, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import validator from 'validator';
import api from "../../services/api";


//import userIco from "../../assets/images/user-ico.png"



const usuarioEmpresaCadastro = () => {
  // get list
  const [consumptions, setConsumptions] = useState([]);
  const state = {
    countShowForm: 0,
  }



  // post form
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [showState, setShowState] = useState(state.countShowForm)
  const [adress, setAdress] = useState({})
  const [company, setCompany] = useState({})
  const [user, setUser] = useState({})

  const confirmSubmit = (data) => {
    // console.log(company)
    //console.log(adress)
    //console.log(user)
    const companyData =
    {
      "NR_CNPJ": company.NR_CNPJ,
      "DS_RAZAO_SOCIAL": company.DS_RAZAO_SOCIAL,
      "DS_TELEFONE": company.DS_TELEFONE,
      "NR_CEP": adress.NR_CEP.replace(/\D/g, ''),
      "DS_LOGRADOURO": adress.DS_LOGRADOURO,
      "DS_BAIRRO": adress.DS_BAIRRO,
      "NR_NUMERO": adress.NR_NUMERO,
      "DS_COMPLEMENTO": getValues('DS_COMPLEMENTO') ? getValues('DS_COMPLEMENTO') : 'N/A',
      "DS_CIDADE": adress.DS_CIDADE,
      "DS_ESTADO": adress.DS_ESTADO,
      "DS_PAIS": adress.DS_PAIS


    }

    api
      .post("/userservice", user)
      .then((response) => {
        if (response.data == "sucesso"){
          api
            .post("/empresaservice", companyData)
            .then((response) => {
              setShowState(0)
              Swal.fire({
                icon: "success",
                text: "Seu cadastro foi realizado com sucesso!",
                timer: 4000,
              });

            }).catch(function (error) {
              console.log(error);
              Swal.fire({
                icon: "warning",
                text: "Não foi possível realizar o cadastro da empresa",
              });
            });
          }else{
            Swal.fire({
              icon: "warning",
              text: response.data,
            });

          }

      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          text: "Não foi possível realizar o cadastro",
        });
      });









  }

  const errorAlert = (e) => {
    Swal.fire({
      timer: 2000,
      text: e,
    });
  }
  const checkCep = (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length == 8) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then(res => res.json()).then(data => {
        setValue('DS_LOGRADOURO', data.logradouro)
        setValue('DS_COMPLEMENTO', data.complemento)
        setValue('DS_BAIRRO', data.bairro)
        setValue('DS_CIDADE', data.localidade)
        setValue('DS_ESTADO', data.uf)


      })
    }
  }
  const companyRegistration = (e) => {
    //  const recaptcha = getValues("confirmaRecaptcha")
    //if(recaptcha){
    const cnpj = getValues("NR_CNPJ").replace(/\D/g, '');

    if (cnpj.length > 13) {
      const checkCnpj = { "NR_CNPJ": getValues("NR_CNPJ") }
      api
        .post("/empresaservice/check", checkCnpj)
        .then((response) => {
          if (response.data.validRegistration) {
            fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`).then(res => res.json()).then(data => {
              if (data.razao_social) {
                setValue('DS_RAZAO_SOCIAL', data.razao_social)
                setValue('CNPJ', getValues("NR_CNPJ"))
                setShowState(1)
              } else {
                Swal.fire({
                  icon: "error",
                  timer: 3000,
                  title: 'CNPJ inválido',
                  text: '',
                });

              }

              //75.315.333/0030-43
            }).catch(function (error) {
              console.log(error);
              Swal.fire({
                icon: "error",
                timer: 2000,
                text: 'Serviço temporáriamente indisponível',
              });
            })



          } else {
            Swal.fire({
              icon: "error",
              timer: 3000,
              title: 'CNPJ Já Cadastrado',
              text: '',
            });
          }

        })


    } else {
      errorAlert("verifique o CNPJ digitado e tente novamente")
    }

    //}else{
    //errorAlert("Preencha o recaptcha")
    //}
  }
  const companyData = (e) => {
    let phone = getValues("DS_TELEFONE").replace(/\D/g, '')
    if (phone.length == 10 || phone.length == 11) {
      setCompany({
        NR_CNPJ: getValues("CNPJ"),
        DS_RAZAO_SOCIAL: getValues("DS_RAZAO_SOCIAL"),
        DS_TELEFONE: getValues("DS_TELEFONE").replace(/\D/g, '')
      })
      // console.log(phone)
      setShowState(2)
    }
  }
  const addressInformation = (e) => {
    const addressInfo = getValues(["NR_CEP", "DS_LOGRADOURO", "DS_BAIRRO", "NR_NUMERO", "DS_CIDADE", "DS_ESTADO"])
    let counter = 0
    // console.log(addressInfo)
    addressInfo.forEach(info => {
      if (!info) {
        counter++
      }
    })
    if (counter == 0) {
      setAdress({
        "NR_CEP": addressInfo[0].replace(/\D/g, ''),
        "DS_LOGRADOURO": addressInfo[1],
        "DS_BAIRRO": addressInfo[2],
        "NR_NUMERO": addressInfo[3].replace(/\D/g, ''),
        "DS_CIDADE": addressInfo[4],
        "DS_ESTADO": addressInfo[5],
      })
      setShowState(3)
    } else {
      errorAlert("Favor preencher todos os dados!")
    }

  }
  const personalInformation = (e) => {
    const personalInfo = getValues(["DS_NOME", "DS_SOBRENOME", "NR_TELEFONE", "DS_EMAIL", "DS_SENHA", "DS_CONFIRMASENHA"])
    let counter = 0
    personalInfo.forEach(info => {
      if (!info) {
        counter++
      }
    })
    if (personalInfo[4] != personalInfo[5]) {
      errorAlert("Favor verificar as senhas digitadas.")
      return
    }

    if (counter == 0) {
      if (!validator.isEmail(personalInfo[3])) {
        errorAlert("Favor digitar um email válido.")
        return
      }
      setUser({
        "DS_NOME": personalInfo[0],
        "DS_SOBRENOME": personalInfo[1],
        "NR_TELEFONE": personalInfo[2].replace(/\D/g, ''),
        "DS_EMAIL": personalInfo[3],
        "DS_SENHA": personalInfo[4],
        // aceitaContato:getValues("confirmaCheck")
      })
      setShowState(4)
      return
    } else {
      errorAlert("Favor preencher todos os dados!")
      return
    }


  }

  const previusState = (e) => {
    state.countShowForm = showState
    if (state.countShowForm > 0) {
      state.countShowForm--
    }
    if (state.countShowForm != 4) {
    }
    setShowState(state.countShowForm)
  }








  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro da Empresa</title>
        </MetaTags>
        <Container fluid>
          {showState == 0 ?
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <h6 className="page-title">Cadastro da Empresa</h6>
                  <ol className="breadcrumb m-0">
                    <p className="breadcrumb-item active">
                      <b>Olá, seja bem vindo(a) ao IoT DMAE.</b>
                    </p>
                    <p>
                      Você iniciará agora o cadastro destinado a Pessoas Jurídicas(PJ).Não são realizados cadastros de pessoas físicas.
                    </p>
                    <p>
                      Após o preenchimento de todas as informações, o sistema enviará um e-mail automático de confirmação dos dados preenchidos. <br></br>
                      Logo que o cadastro for confirmado pelo usuário, a equipe do DMAE fará a análise das informações e se não houver qualquer divergência, seu acesso será liberado.
                    </p>
                  </ol>
                </Col>
              </Row>
            </div>
            : null}
          {showState == 0 ?
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3">
                    <h6 className="page-title">Cadastro da Empresa</h6>

                    <div className="col-md-6 ">
                      <label
                        className="col-md-12col-form-label"
                      >CNPJ*
                      </label>
                      <ReactInputMask
                        {...register("NR_CNPJ")} //onBlur={checkCnpj}
                        mask="99.999.999/9999-99"
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                    <Row className="mb-3">
                      <div className="col-md-6 mt-2 ">
                        <Button
                          color="info"
                          className="btn-lg btn-primary"
                          type="button"
                          value='1'
                          onClick={companyRegistration}
                        >
                          Continuar
                        </Button>
                      </div>
                    </Row>

                  </Row>
                </div>
              </Col>
            </Row>
            : null}
          {showState == 1 ?
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3">
                    <h6 className="page-title">Dados da Empresa</h6>

                    <div className="col-md-6">
                      <label
                        className="col-md-12col-form-label"
                      >CNPJ*
                      </label>
                      <input
                        {...register("CNPJ")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                        disabled

                      />
                      <label
                        className="col-md-12col-form-label"
                      >Razão Social*
                      </label>
                      <input
                        {...register("DS_RAZAO_SOCIAL")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                        disabled

                      /> <label
                        className="col-md-12col-form-label"
                      >Telefone*
                      </label>
                      <ReactInputMask
                        {...register("DS_TELEFONE")}
                        mask="(99)99999-9999"
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
                      <Row className="mb-3">
                        <div className="col-md-6 mt-2">
                          <span onClick={previusState}><i className="ti-angle-left"></i> Voltar </span>
                          <Button
                            color="info"
                            className="btn-lg btn-primary ml-5"
                            type="button"
                            value='1'
                            onClick={companyData}
                          >
                            Continuar
                          </Button>
                        </div>
                      </Row>

                    </div>

                  </Row>
                </div>
              </Col>
            </Row>
            : null}
          {showState == 2 ?
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3">
                    <h6 className="page-title">Informações de endereço</h6>

                    <div className="col-md-6">
                      <label
                        className="col-md-12col-form-label"
                      >CEP*
                      </label>
                      <ReactInputMask
                        {...register("NR_CEP")} onBlur={checkCep}
                        mask="99999-999"
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
                      <label
                        className="col-md-12col-form-label"
                      >Logradouro*
                      </label>
                      <input
                        {...register("DS_LOGRADOURO")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
                      <Row>
                        <Col lg={6}>
                          <label
                            className="col-md-12col-form-label"
                          >Bairro*
                          </label>
                          <input
                            {...register("DS_BAIRRO")}
                            className="form-control form-control-lg w-100"
                            type="text"
                            defaultValue=""
                          />
                        </Col>
                        <Col lg={6}>
                          <label
                            className="col-md-12col-form-label"
                          >Número*
                          </label>
                          <input
                            {...register("NR_NUMERO")}
                            className="form-control form-control-lg w-100"
                            type="text"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <label
                        className="col-md-12col-form-label"
                      >Complemento
                      </label>
                      <input
                        {...register("DS_COMPLEMENTO")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                      />
                      <label
                        className="col-md-12col-form-label"
                      >Cidade*
                      </label>
                      <input
                        {...register("DS_CIDADE")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                        disabled
                      />
                      <label
                        className="col-md-12col-form-label"
                      >Estado*
                      </label>
                      <input
                        {...register("DS_ESTADO")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue=""
                        disabled
                      />
                      <label
                        className="col-md-12col-form-label"
                      >País*
                      </label>
                      <input
                        {...register("DS_PAIS")}
                        className="form-control form-control-lg"
                        type="text"
                        defaultValue="Brasil"
                        disabled
                      />


                    </div>
                    <Row className="mb-3">

                      <div className="col-md-6 mt-2">
                        <span onClick={previusState} className="align-middle"><i className="ti-angle-left"></i> Voltar </span>
                        <Button
                          color="info"
                          className="btn-lg btn-primary ml-2"
                          type="button"
                          value='1'
                          onClick={addressInformation}
                        >
                          Continuar
                        </Button>
                      </div>
                    </Row>

                  </Row>
                </div>
              </Col>
            </Row>
            : null}
          {showState == 3 ?
            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3" >
                    <h6 className="page-title">Informações Pessoais</h6>
                    <div className="col-md-12">


                      <div className="col-md-6">

                        <label
                          className="col-md-12col-form-label"
                        >Nome*
                        </label>
                        <input
                          {...register("DS_NOME")}
                          className="form-control form-control-lg"
                          type="text"
                          defaultValue=""
                        />
                        <label
                          className="col-md-12col-form-label"
                        >Sobrenome*
                        </label>
                        <input
                          {...register("DS_SOBRENOME")}
                          className="form-control form-control-lg"
                          type="text"
                          defaultValue=""
                        /><label
                          className="col-md-12col-form-label"
                        >Telefone*
                        </label>
                        <ReactInputMask
                          {...register("NR_TELEFONE")}
                          mask="(99)99999-9999"
                          className="form-control form-control-lg"
                          type="text"
                          defaultValue=""
                        />
                        <label
                          className="col-md-12col-form-label"
                        >Email*
                        </label>
                        <input
                          {...register("DS_EMAIL")}
                          className="form-control form-control-lg "
                          type="email"
                          placeholder="exemplo@email.com"
                          defaultValue=""
                        />
                        <p
                          {...register("validemail")}
                        />
                        <Row>
                          <Col lg={6}>
                            <label
                              className="col-md-12col-form-label"
                            >Senha*
                            </label>
                            <input
                              {...register("DS_SENHA")}
                              className="form-control form-control-lg w-100"
                              type="password"
                              defaultValue=""
                              maxLength={10}
                            />
                          </Col>
                          <Col lg={6}>
                            <label
                              className="col-md-12 col-form-label"
                            >Confirmar Senha*
                            </label>
                            <input
                              {...register("DS_CONFIRMASENHA")}
                              className="form-control form-control-lg "
                              type="password"
                              defaultValue=""
                              maxLength={10}
                            />



                          </Col>
                        </Row>
                        <p><input
                          {...register("confirmaCheck")}
                          type="checkbox"
                        /> Aceito receber conteúdos e promoções por email.</p>


                      </div>
                      <Row className="mb-3">
                        <div className="col-md-6 mt-2">
                          <span onClick={previusState}><i className="ti-angle-left"></i> Voltar </span>
                          <Button
                            color="info"
                            className="btn-lg btn-primary"
                            type="button"
                            value='1'
                            onClick={personalInformation}
                          >
                            Continuar
                          </Button>
                        </div>
                      </Row>

                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
            : null}
          {showState == 4 ?

            <Row>
              <Col lg={12}>
                <div className="page-title-box">
                  <Row className="mb-3" >
                    <h6 className="page-title">Confirmação de Cadastro</h6>
                    <div className="col-md-3"></div>
                    <p>
                      Olá, {getValues("DS_NOME")}.<br></br>
                      Seu pré-cadastro foi realizado com sucesso.
                    </p>
                    <p>
                      Para sua segurança, pedimos que acesse o botão abaixo e confirme seu cadastro
                    </p>


                    <div className="col-md-6 mt-2">
                      <span onClick={previusState}><i className="ti-angle-left"></i>Voltar </span>
                      <Button
                        color="info"
                        className="btn-lg btn-primary"
                        type="button"
                        value='-1'
                        onClick={confirmSubmit}
                      >
                        Confirmar
                      </Button>
                    </div>

                  </Row>
                </div>
              </Col>
            </Row>
            : null}

        </Container>

      </div>

    </React.Fragment>)
};

export default usuarioEmpresaCadastro;
