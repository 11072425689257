import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Card, CardBody, Container, Row, Button } from "reactstrap";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const editFabricanteLora = () => {
  const [fabricante, setFabricante] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");

  let { id } = useParams();

  useEffect(() => {
    api.get(`/makerslora/${id}`).then((response) => {
      const aux = response.data[0];
      setFabricante(aux.DS_FABRICANTE_LORA);
      setContato(aux.NM_CONTATO);
      setEmail(aux.DS_EMAIL);
      setTel(aux.NR_TELEFONE);
    });
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    let body = {
      DS_FABRICANTE_LORA: fabricante,
      NM_CONTATO: contato,
      DS_EMAIL: email,
      NR_TELEFONE: tel,
    };
    api
      .put(`/makerslora/${id}`, body)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Fabricante atualizado!",
        });
      })
      .catch(function (error) {
      });
  };

  const inputHandler = (prop) => (event) => {
    if (prop === "fabricante") {
      setFabricante(event.target.value);
    } else if (prop === "contato") {
      setContato(event.target.value);
    } else if (prop === "email") {
      setEmail(event.target.value);
    } else if (prop === "tel") {
      setTel(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Fabricante Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/FabricanteLora">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Fabricante
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_FABRICANTE_LORA"
                        value={fabricante}
                        onChange={inputHandler("fabricante")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Contato
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NM_CONTATO"
                        value={contato}
                        onChange={inputHandler("contato")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      E-mail
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_EMAIL"
                        value={email}
                        onChange={inputHandler("email")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Telefone
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_TELEFONE"
                        value={tel}
                        onChange={inputHandler("tel")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Atualizar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default editFabricanteLora;
