import React from "react"
import { Container, Row, Col } from "reactstrap"
import logos from "../../assets/images/logo_padrao_alinhado.png"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div className="col-12">
                <img src={logos} alt="" height="50" style={{position:'relative',top:'-18px'}}/>
              
            </div>
            <div className="col-12">
              © {new Date().getFullYear()} DMAE
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
