import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button, Card,
  CardBody, Col, Container,
  Row
} from "reactstrap";

import Swal from "sweetalert2";
import api from "../../services/api";


const ExcelFile = ReactExport.ExcelFile; 
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EquipamentoLora = () => {
  const [showType,setShowType] = useState("S")

  const initialState = {
    maker: "",
    desc: "",
    model: "", 
    number: "",
    status: "",
    type: showType 
  };
  const [values, setValues] = useState([]);
  const [makers, setMakers] = useState([]);
  const [equips, setEquips] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [loraSearch, setLoraSearch] = useState(0);


  let history = useHistory();
  const routeChange = (newRoute) => {
    history.push(newRoute)
  }
  useEffect(() => {
    let maker = [];
    api.get("/makerslora").then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_FABRICANTE_LORA,
          name: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(maker);
    });
  }, []);

  useEffect(() => {
    let equip = [];
    api.get("/equipmentslora").then((response) => {
      equip = formatArray(response.data);
      setValues(equip);
      setEquips(equip);
    });
  }, [makers]);

  const submitHandler = (event) => {
    event.preventDefault();
    let body = {
      OID_FABRICANTE_LORA: "",
      DS_DESCRICAO_EQUIPAMENTO: search.desc,
      DS_MODELO_EQUIPAMENTO: search.model,
      NR_EQUIPAMENTO: search.number,
      TP_ATIVO:search.type
    };
    if (search.maker !== "") {
      makers.map((elem) => {
        if (elem.name === search.maker) {
          body.OID_FABRICANTE_LORA = elem.id;
        }
      });
    }

    api.post("/equipmentslora/filter", body)
      .then((response) => {
        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setValues(equips);
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          setValues(formatArray(response.data));
          const aux = search.maker;
          setSearch({ ...initialState, maker: aux });
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Preencha ao menos um campo para realizar a busca",
        });
      });
  };

  const formatArray = (data) => {
    let aux = [];
    makers.map((elem) => {
      data.map((elem2) => {
        if (elem.id === elem2.OID_FABRICANTE_LORA) {
          aux.push({
            id: elem2.OID_EQUIPAMENTO_LORA,
            makerId: elem.id,
            maker: elem.name,
            desc: elem2.DS_DESCRICAO_EQUIPAMENTO,
            model: elem2.DS_MODELO_EQUIPAMENTO,
            number: elem2.NR_EQUIPAMENTO,
            status: elem2.TP_ATIVO,
            pulso: elem2.NR_VLR_PULSO
          });
        }
      });
    });
    return aux;
  };
  const confirmDelete = (idLora) => {
    api.put(`/equipmentslora/delete/${idLora}`).then((response) => {
      Swal.fire({
        icon: "success",
        text: "Equipamento Lora Deletado",
        timer: 2000,
      });
      api.get("/equipmentslora").then((response) => {
        let equip = [];
        equip = formatArray(response.data);
        setValues(equip);
        setEquips(equip);
      });
    }).catch((error) => {
      Swal.fire({
        icon: "warning",
        text: "Não foi possível fazer a exclusão, por favor contate o administrador!",
      });
    })

  }
  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    if(prop == "type"){
      setShowType(event.target.value)
    }
  };
  const validLora = async (idLora,nomeLora) => {
    const body = {
      OID_HIDROMETRO: "",
      OID_EQUIPAMENTO_LORA: idLora,
      DS_LOCALIDADE: "",
      DS_LOCALIZACAO_REGIAO: "",
      TP_LOCALIZACAO: "",
    }


    await api.post("/equipmentsdmae/filter", body)
      .then((response) => {
        const arr = response.data;

        if (arr.length == 0) {
          Swal.fire({
            title: "Deseja deletar equipamento \n" + nomeLora + " ?",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não"
          }).then((result) => {
            if (result.isConfirmed) {
              confirmDelete(idLora);
            }
          })

        } else if (arr.length == 1) {
          Swal.fire({
            title: "Equipamento Lora vinculado ao endereço\n\n " + arr[0].DS_LOCALIDADE + "\n\n ",
    
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Entendido',
          }).then((result) => {
            if (result.isConfirmed) {
            }
          })
        }


      }).catch((e) => { console.error("erro" + e) })


  }




  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Equipamento Lora</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Equipamento Lora</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <Link to="/cadastro/EquipamentoLora-cadastro">
                    <Button color="primary" className="btn-lg">
                      Cadastrar <i className="dripicons-document-new" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={submitHandler}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_FABRICANTE_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Fabricante Lora
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_FABRICANTE_LORA"
                        className="form-control form-control-lg"
                        value={search.maker}
                        onChange={onChangeHandler("maker")}
                      >
                        <option value="">Selecione...</option>
                        {makers?.map((elem, key) => (
                          <option key={key} value={elem.name}>
                            {elem.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_DESCRICAO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Descrição Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_EQUIPAMENTO"
                        value={search.desc}
                        onChange={onChangeHandler("desc")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_MODELO_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Modelo Equipamento
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_EQUIPAMENTO"
                        value={search.model}
                        onChange={onChangeHandler("model")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <label
                      htmlFor="NR_EQUIPAMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Número Equipamento
                      <div></div>
                      (MAC ADDRESS)
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_EQUIPAMENTO"
                        value={search.number}
                        onChange={onChangeHandler("number")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Status
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        id="TP_ATIVO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("type")}
                      >
                        <option value="S">Ativo</option>
                        <option value="N">Inativo</option>
                      </select>
                    </div>
                        </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <ExcelFile filename ='Equipamento Lora'>
            <ExcelSheet data={values} name="consumptions">
              <ExcelColumn label="Fabricante Lora" value="maker" />
              <ExcelColumn label="Descrição Equipamento" value="desc" />
              <ExcelColumn label="Modelo Equipamento" value="model" />
              <ExcelColumn label="Número Equipamento" value="number" />
            </ExcelSheet>
          </ExcelFile>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Fabricante Lora</th>
                  <th className="align-middle">Descrição Equipamento</th>
                  <th className="align-middle">Modelo Equipamento</th>
                  <th className="align-middle">Número Equipamento</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle">Ultimo Pulso</th>
                  <th className="align-middle"></th>
                </tr>
              </thead>
              <tbody>
                {values?.map((value, key) => (
                  <tr key={"_tr_" + key}>
                    <td>{value.maker}</td>
                    <td>{value.desc}</td>
                    <td>{value.model}</td>
                    <td>{value.number}</td>
                   
                    <td>
                      {value.status == "S" ? (
                        <span className="badge bg-success">Ativo</span>
                      ) : (
                        <span className="badge bg-danger bg-primary">
                          Inativo
                        </span>
                      )}
                    </td>
                    <td>{value.pulso}</td>
                    <td>

                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { routeChange(`/cadastro/EquipamentoLora-edit/${value.id}`) }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>

                      <Button
                        type="button"
                        onClick={() => { setLoraSearch(value.id);  validLora(value.id,value.number); }}
                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EquipamentoLora;
